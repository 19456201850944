<script lang="ts">
  import { fade } from 'svelte/transition';
  import { newComment } from '../data/API';
  import type { PostComment } from '../data/Post';

  export let postId;
  export let comments: PostComment[];

  let showToolbar = false;
  let text = '';
  let name = '';
  let phone = '';

  const focus = (e) => {
    showToolbar = true;
  };

  const blur = (e) => {
    if (text.length === 0) showToolbar = false;
  };

  const onSaveClick = async () => {
    grecaptcha.ready(function () {
      grecaptcha.execute('6LfdgCQbAAAAANDmhNkTngGT2t4fPXHx9gG12r_2', { action: 'submit' }).then(async function (token) {
        try {
          await newComment(token, postId, text, name, phone);
          text = '';
          name = '';
          phone = '';
        } catch (e) {
          console.error(e);
        }
      });
    });
  };
</script>

<div class="py-8 border border-gray-500 border-l-0 border-r-0 border-b-0">
  <table class="w-full">
    <thead><tr><th></th><th></th></tr></thead>
    <tbody>
      <tr>
        {#if comments.filter((c) => c.postId === postId).length === 0}
          <td class="p-0 pr-4 ">
            <button style="font-family: monospace" class="w-12 h-12 bg-black text-white text-3xl">К</button>
          </td>
          <td class="w-full">
            <div class="text-xl">Коментарии отсутствуют</div>
          </td>
        {:else}
          <td class="p-0 pr-4 ">
            <button style="font-family: monospace" class="w-12 h-12 bg-black text-white text-3xl"
              >{comments.filter((c) => c.postId === postId).length}</button
            >
          </td>
          <td class="w-full">
            <div class="text-xl">Коментарий</div>
          </td>
        {/if}
      </tr>
      <tr>
        <td class="p-0 pr-4">
          <div class="flex justify-center">
            <div style="width: 1px;" class="h-12 bg-black"></div>
          </div>
        </td>
        <td></td>
      </tr>

      {#each comments.filter((c) => c.postId === postId) as c, i}
        <tr>
          <td class="p-0 pr-4 ">
            <button style="font-family: monospace" class="w-12 h-12 border border-black bg-white text-black text-3xl"
              >{i + 1}</button
            >
          </td>
          <td rowspan="2" class="w-full" style="vertical-align: top">
            <div class="flex flex-col h-full">
              <div class="text-base mb-2">
                <span>{c.name}</span>&nbsp;<span class="text-gray-500">{c.date.substr(0, 10)}</span>
              </div>
              <div class="text-base text-gray-400">
                {c.text}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="p-0 pr-4">
            <div class="flex justify-center">
              <div style="width: 1px; min-height: 3rem;" class="bg-black"></div>
            </div>
          </td>
        </tr>
      {/each}

      <tr>
        <td class="p-0 pr-4">
          <button style="font-family: monospace" class="w-12 h-12 bg-black text-white text-3xl">+</button>
        </td>
        <td class="w-full">
          <div class="text-xl">Оставить комментарий</div>
        </td>
      </tr>
      <tr>
        <td></td>
        <td class="w-full">
          <div class="py-4 flex flex-col">
            <textarea
              bind:value="{text}"
              on:focus="{focus}"
              on:blur="{blur}"
              class="w-full border border-gray-500 p-4"
              placeholder="текст комментария..."
              rows="4"></textarea>
            {#if showToolbar}
              <div
                transition:fade
                class="bg-gray-100 py-8 px-4 border border-gray-300 border-l-0 border-r-0 border-t-0 mb-4"
              >
                <div class="flex flex-col mb-2">
                  <label class="mb-1 text-xs">имя:</label>
                  <input bind:value="{name}" class="border border-gray-300 px-2 py-1 text-xs" />
                </div>

                <div class="flex flex-col mb-2">
                  <label class="mb-1 text-xs">email:</label>
                  <input bind:value="{phone}" class="border border-gray-300 px-2 py-1 text-xs" />
                </div>
              </div>

              <div class="flex justify-center">
                <button on:click="{onSaveClick}" class="border border-gray-300 bg-white px-4 py-1 rounded text-black"
                  >сохранить</button
                >
              </div>
            {/if}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
