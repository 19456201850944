<script lang="ts">
  import AppLogo from './AppLogo.svelte';
  import AppSearch from './AppSearch.svelte';
  import MobileMenu from './MobileMenu.svelte';
  import SubscribeForm from './SubscribeForm.svelte';
  import { SocialLinks } from '../data/SocialLinks';

  let showMenu = false;

  const openMenu = () => {
    showMenu = true;
  };

  let showSubscribeForm = false;
  const onSubscribeClick = () => {
    showSubscribeForm = true;
  };
</script>

{#if showSubscribeForm}
  <SubscribeForm on:close="{() => (showSubscribeForm = false)}" />
{/if}

<div class="flex lg:items-center flex-col lg:flex-row py-2">
  <div class="flex items-center">
    <AppLogo />

    <button on:touchstart="{openMenu}" class="ml-auto lg:hidden text-xl p-4">
      <i class="fas fa-bars"></i>
    </button>
  </div>

  <AppSearch />

  <div
    class="flex items-center justify-between lg:justify-end py-4 lg:py-0 border lg:border-0 border-black border-l-0 border-r-0 border-b-0 lg:ml-4"
  >
    <button
      style="height: 36px;"
      on:click="{onSubscribeClick}"
      class="text-sm lg:text-base border border-gray-900 hover:bg-gray-900 text-gray-900 hover:text-white border-solid px-3 lg:px-6 mr-8"
    >
      Подписаться
    </button>

    <div class="flex items-center text-2xl">
      <a
        class="text-3xl mx-1 flex items-center justify-center"
        target="_blank"
        href="https://www.instagram.com/skillady_/"
      >
        <i class="fab fa-instagram"></i>
      </a>

      {#each $SocialLinks as item}
        {#if !item.hidden}
          <a class="with-border mx-1 flex items-center justify-center" target="_blank" href="{item.link}">
            <i class="{item.icon}"></i>
          </a>
        {/if}
      {/each}
    </div>
  </div>
</div>

{#if showMenu}
  <MobileMenu on:close="{() => (showMenu = false)}" />
{/if}

<style>
  .with-border {
    border: 2px solid #000000;
    outline: 0;
    height: 26px;
    width: 26px;
    border-radius: 6px;
    font-size: 14px;
  }
</style>
