<script lang="ts">
  import { onMount } from 'svelte';

  onMount(async () => {
    if (location.host !== 'jsdom.ssr') {
      document.documentElement.scrollTop = 0;
    }
    setTimeout(() => {
      dispatchEvent(new CustomEvent('app-loaded'));
    }, 100);
  });
</script>

<div class="container mx-auto px-8 pb-32 text-lg">
  <h1 class="text-2xl md:text-6xl text-center pb-8">Политика конфиденциальности</h1>

  <div class="mb-8">Последнее изменение Май 3, 2020 г.</div>

  <div class="mb-8 font-bold">1. Общие условия</div>

  <div class="mb-8">
    1.1. Настоящая Политика определяет порядок обработки и защиты Администрацией Сайта информацию о физических лицах
    (далее – Пользователь), которая может быть получена Администрацией Сайта при посещении веб-сайта https://skillady.com
  </div>

  <div class="mb-8">
    1.2. Целью настоящей Политики является обеспечение надлежащей защиты персональной информации, которую Пользователь
    предоставляет о себе самостоятельно при использовании Сервисов или в процессе регистрации (создании учетной записи)
    от несанкционированного доступа и разглашения.
  </div>

  <div class="mb-8">
    1.3. Отношения, связанные со сбором, хранением, распространением и защитой информации предоставляемой Пользователем,
    регулируются настоящей Политикой, иными официальными документами Администрации Сайта и действующим законодательством
    Российской Федерации.
  </div>

  <div class="mb-8">
    1.4. Регистрируясь на Сайте и используя Сайт и Сервисы Пользователь выражает свое полное согласие с условиями
    настоящей Политики.
  </div>

  <div class="mb-8 font-bold">2. Цели сбора, обработки и хранения информации предоставляемой Пользователем Сайта</div>

  <div class="mb-8">
    2.1. Обработка персональных данных Пользователя осуществляется в соответствии с законодательством Российской
    Федерации. Администрация Сайта обрабатывает персональные данные Пользователя в целях:
  </div>

  <ul class="mb-8">
    <li>идентификации стороны в рамках соглашений и договоров, заключаемых с Администрацией Сайта;</li>
    <li>предоставления Пользователю доступа к Сайту, Сервисам;</li>
    <li>
      связи с Пользователем, направлении Пользователю транзакционных писем в момент получения заявки регистрации на Сайте
      или получении оплаты от Пользователя, разово, если Пользователь совершает эти действия, направлении Пользователю
      уведомлений, запросов;
    </li>
    <li>направлении Пользователю сообщений рекламного и/или информационного характера;</li>
    <li>
      проверки, исследования и анализа таких данных, позволяющих поддерживать и улучшать услуги Сервиса, также
      разрабатывать новые услуги Сервиса;
    </li>
    <li>проведение статистических и иных исследований, на основе обезличенных данных;</li>
    <li>
      обработка вышеуказанных персональных данных может осуществляться путем смешанной обработки персональных данных, и
      может включать в себя сбор, систематизацию, накопление, хранение, уточнение, обезличивание, блокирование,
      уничтожение персональных данных;
    </li>
    <li>
      персональные данные могут передаваться третьим лицам с использованием сетей общего пользования и международного
      информационного обмена, с использованием трансграничной передачи данных на территории иностранных государств, в том
      числе и в случае, если этими странами не обеспечивается адекватная защита прав субъектов персональных данных с
      целью получения Пользователем товаров/услуг, а также для достижения иных целей;
    </li>
    <li>
      персональные данные могут обрабатываться в течение всего срока исковой давности, предусмотренного законодательством
      Российской Федерации в отношении заключенного Пользователем с Администрацией Сайта и/или Партнерами Договора.
    </li>
  </ul>

  <div class="mb-8 font-bold">
    3. Условия обработки персональной информации предоставленной Пользователем и ее передачи третьим лицам
  </div>

  <div class="mb-8">
    3.1. Администрация Сайта принимает все необходимые меры для защиты персональных данных Пользователя от неправомерного
    доступа, изменения, раскрытия или уничтожения.
  </div>

  <div class="mb-8">
    3.2. Администрация Сайта предоставляет доступ к персональным данным Пользователя только тем работникам, подрядчикам и
    Партнерам, которым эта информация необходима для обеспечения функционирования Сервиса и оказания Услуг, продажи
    товаров и/или предоставлении услуг, получении неисключительной лицензии Пользователем.
  </div>

  <div class="mb-8">
    3.3. Администрация Сайта вправе использовать предоставленную Пользователем информацию, в том числе персональные
    данные, в целях обеспечения соблюдения требований действующего законодательства Российской Федерации (в том числе в
    целях предупреждения и/или пресечения незаконных и/или противоправных действий Пользователей). Раскрытие
    предоставленной Пользователем информации может быть произведено лишь в соответствии с действующим законодательством
    Российской Федерации по требованию суда, правоохранительных органов, а равно в иных предусмотренных законодательством
    Российской Федерации случаях.
  </div>

  <div class="mb-8">
    3.4. Администрация Сайта не проверяет достоверность информации предоставляемой Пользователем и исходит из того, что
    Пользователь в рамках добросовестности предоставляет достоверную и достаточную информацию, заботится о
    своевременности внесения изменений в ранее предоставленную информацию при появлении такой необходимости, включая, но
    не ограничиваясь изменение номера телефона.
  </div>

  <div class="mb-8 font-bold">4. Условия пользования Сайта</div>

  <div class="mb-8">4.1. Пользователь при пользовании Сайта, подтверждает, что:</div>

  <ul class="mb-8">
    <li>
      обладает всеми необходимыми правами, позволяющими ему осуществлять регистрацию (создание учетной записи) и
      использовать Услуги Сайта;
    </li>
    <li>
      указывает достоверную информацию о себе в объемах, необходимых для пользования Услугами Сайта, обязательные для
      заполнения поля для дальнейшего предоставления Услуг Сайта помечены специальным образом, вся иная информация
      предоставляется Пользователем по его собственному усмотрению;
    </li>
    <li>
      осознает, что информация на Сайте, размещаемая Пользователем о себе, может становиться доступной для третьих лиц,
      не оговоренных в настоящей Политике и может быть скопирована и распространена ими;
    </li>
    <li>
      ознакомлен с настоящей Политикой, выражает свое согласие с ней и принимает на себя указанные в ней права и
      обязанности. Ознакомление с условиями настоящей Политики и/или проставления в чекбоксе галочки в графе на данную
      Политику является письменным согласием Пользователем на сбор, хранение, обработку и передачу третьим лицам
      персональных данных предоставляемых Пользователем.
    </li>
  </ul>

  <div class="mb-8">
    4.2. Администрация Сайта не проверяет достоверность получаемой (собираемой) информации о Пользователе, за исключением
    случаев, когда такая проверка необходима в целях исполнения обязательств перед Пользователем.
  </div>

  <div class="mb-8 font-bold">
    5. В рамках настоящей Политики под «персональной информацией Пользователя» понимаются:
  </div>

  <div class="mb-8">
    5.1. Данные предоставленные Пользователем самостоятельно при пользовании Сервисов Сайта включая но, не ограничиваясь:
    Фамилия, имя, отчество; Фамилия, имя в латинской транскрипции, как они указаны в заграничном паспорте; Год, месяц и
    число рождения; Место рождения; Пол; Данные об общегражданском паспорте Российской Федерации: Серия и номер
    общероссийского паспорта; дата его выдачи; наименование органа, выдавшего паспорт; срок действия общероссийского
    паспорта либо свидетельства о рождении; Адрес регистрации; Адрес электронной почты; Домашний и контактный (мобильный)
    телефоны; Гражданство при рождении и в настоящее время; Семейное положение; Данные об образовании и ученой степени;
    Данные о работодателе и работе: наименование, адрес и телефон работодателя; должность в настоящее время; размер
    заработной платы; данные о росте, весе и состоянии здоровья.
  </div>

  <div class="mb-8">
    5.2. Данные, которые автоматически передаются Сервисам Сайта в процессе их использования с помощью установленного на
    устройстве Пользователя программного обеспечения, в том числе IP-адрес, информация из cookie, информация о браузере
    Пользователя (или иной программе, с помощью которой осуществляется доступ к Сервисам Сайта), время доступа, адрес
    запрашиваемой страницы.
  </div>

  <div class="mb-8">
    5.3. Иная информация о Пользователе, сбор и/или предоставление которой определено в Регулирующих документах отдельных
    Сервисов Администрации Сайта.
  </div>

  <div class="mb-8 font-bold">6. Изменение и удаление персональных данных</div>

  <div class="mb-8">
    6.1. Пользователь может в любой момент изменить (обновить, дополнить) предоставленную им персональную информацию или
    её часть, а также параметры её конфиденциальности, воспользовавшись функцией редактирования персональных данных в
    разделе, либо в персональном разделе соответствующего Сайта. Пользователь обязан заботится о своевременности внесения
    изменений в ранее предоставленную информацию, ее актуализации, в противном случае Администрация Сайта не несет
    ответственности за неполучение уведомлений, товаров/услуг и т.п.
  </div>

  <div class="mb-8">
    6.2. Пользователь также может удалить предоставленную им в рамках определенной учетной записи персональную
    информацию. При этом удаление аккаунта может повлечь невозможность использования некоторых Сервисов Сайта.
  </div>

  <div class="mb-8 font-bold">7. Изменение Политики конфиденциальности. Применимое законодательство</div>

  <div class="mb-8">
    7.1. Администрация Сайта имеет право вносить изменения в настоящую Политику конфиденциальности. При внесении
    изменений в актуальной редакции указывается дата последнего обновления. Новая редакция Политики вступает в силу с
    момента ее размещения, если иное не предусмотрено новой редакцией Политики. Действующая редакция всегда находится на
    странице по адресу https://skillady.com/index/politik
  </div>

  <div class="mb-8">
    7.2. К настоящей Политике и отношениям между Пользователем и Администрацией Сайта возникающим в связи с применением
    Политики конфиденциальности, подлежит применению право Российской Федерации.
  </div>
</div>

<style>
  li {
    margin-left: 2rem;
    list-style: circle;
  }
</style>
