<script lang="ts">
  import AppHeader from '../components/AppHeader.svelte';
  import AppFooter from '../components/AppFooter.svelte';
  import { onMount } from 'svelte';
  import { fade } from 'svelte/transition';

  onMount(() => {
    if (location.host !== 'jsdom.ssr') {
      document.documentElement.scrollTop = 0;
    }
  });
</script>

<svelte:head>
  <title>Страница не найдена</title>
</svelte:head>

<AppHeader />

<div class="container mx-auto px-8 pb-8" in:fade="{{ duration: 500 }}">
  <div class="flex items-center justify-center">
    <div class="flex-1">
      <div class="four-o-four text-gray-500">404</div>
    </div>

    <div class="bg-gray-500" style="width: 1px; height: 500px"></div>

    <div class="flex-1 px-8">
      <div class="text-4xl mb-4">Страница не найдена</div>
      <div class="text-xl">Страница которую вы хотели открыть, не найдена.</div>
      <div class="text-xl">Возможно, введен некорректный адрес или страница была удалена.</div>
    </div>
  </div>
</div>

<AppFooter />

<style>
  .four-o-four {
    font-size: 20rem;
    text-shadow: 2px 2px 9px rgba(0, 0, 0, 0.75);
  }
</style>
