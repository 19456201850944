<script lang="ts">
  import { onMount } from 'svelte';
  import { deleteComment, getAllComments } from '../data/API';
  import dayjs from 'dayjs';

  let comments = [];

  onMount(async () => {
    await loadComments();
  });

  const loadComments = async () => {
    const resp = await getAllComments();
    resp.sort((a, b) => (a.date < b.date ? 1 : a.date > b.date ? -1 : 0));

    comments = [...resp];
  };

  const onDeleteComment = async (item) => {
    await deleteComment(item.id);
    await loadComments();
  };
</script>

<div class="flex-1 ">
  <div class="py-8 text-center text-2xl">Комментарии</div>
  <div class="main">
    <table class="w-full">
      <thead>
        <tr>
          <th class="bg-blue-200 p-2"></th>
          <th class="bg-blue-200 p-2">Имя</th>
          <th class="bg-blue-200 p-2">Статья</th>
          <th class="bg-blue-200 p-2">Дата</th>
          <th class="bg-blue-200 p-2">Содержание</th>
          <th class="bg-blue-200 p-2"></th>
        </tr>
      </thead>
      <tbody>
        {#each comments as item, index}
          <tr>
            <td class="bg-blue-100 p-2"> </td>
            <td
              class="bg-blue-100 p-2 whitespace-no-wrap"
              style="max-width:100px; overflow: hidden; text-overflow: ellipsis"
            >
              {item.name}
            </td>
            <td
              class="bg-blue-100 p-2 whitespace-no-wrap"
              style="max-width: 100px; overflow: hidden; text-overflow: ellipsis"
            >
              {item.postTitle}
            </td>
            <td class="bg-blue-100 p-2">
              {dayjs(item.date).format('DD.MM.YYYY')}
            </td>
            <td
              class="bg-blue-100 p-2 whitespace-no-wrap"
              style="max-width: 200px;overflow: hidden; text-overflow: ellipsis"
            >
              {item.text}
            </td>
            <td class="bg-blue-100 p-2 text-center">
              <button on:click="{() => onDeleteComment(item)}" class="text-red-700">
                <i class="far fa-trash-alt"></i>
              </button>
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
</div>

<style>
  .main {
    height: calc(100vh - 172px);
    overflow: auto;
    position: relative;
  }
  table {
    width: 100%;
  }
</style>
