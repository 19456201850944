<script lang="ts">
  import { Authors } from '../data/Author';
  import type { Author } from '../data/Author';
  import { Posts } from '../data/Post';
  import type { PostItem } from '../data/Post';
  import PostPreview from '../components/PostPreview.svelte';
  import { onDestroy, onMount, tick } from 'svelte';
  import { io } from 'socket.io-client';
  import config from '../config.json';
  import { getPosts } from '../data/API';

  export let id: string;

  let data: Author = {
    id: '',
    name: '',
  };

  let posts: PostItem[] = [];
  const socket = io(config.backend);

  const loadData = async (_authors, _id) => {
    if (_authors?.length) {
      data = _authors.find((a) => a.id === _id);
      posts = await getPosts({ authorId: _id });

      await tick();
      dispatchEvent(new CustomEvent('app-loaded'));
    }
  };

  onMount(() => {
    if (location.host !== 'jsdom.ssr') {
      document.documentElement.scrollTop = 0;

      socket.on('refresh posts', () => {
        loadData($Authors, id);
      });
    }
  });

  onDestroy(() => {
    socket.off('refresh posts');
  });

  $: loadData($Authors, id);
</script>

<div class="container mx-auto p-4 md:px-8">
  <div class="flex md:py-8 pb-8 flex-col md:flex-row">
    <div class="author-picture mb-8">
      <img alt="" src="{data.picture}" />
    </div>

    <div class="flex-1 md:px-8">
      <div class="text-3xl md:text-5xl mb-4">{data.name}</div>
      {#if data.web && data.web.length}
        <div>
          <a href="{data.web}" target="_blank" class="block underline mb-4">{data.web.replace(/^https?:\/\//, '')}</a>
        </div>
      {/if}

      <div class="flex flex-col items-start text-2xl mb-4">
        {#if data.instagram && data.instagram.length}
          <a href="https://instagram.com/{data.instagram}" target="_blank" class="flex items-center social-link">
            <span class="text-center text-3xl mr-2 block ">
              <i class="fab fa-instagram"></i>
            </span>
            <span class="block text-xs">{data.instagram}</span>
          </a>
        {/if}

        {#if data.facebook && data.facebook.length}
          <a
            href="https://www.facebook.com/{data.facebook}"
            target="_blank"
            class="flex items-center mr-8 flex items-center justify-center social-link"
          >
            <span class="with-border text-center mr-2 block flex items-center justify-center">
              <i class="fab fa-facebook-f"></i>
            </span>
            <span class="block text-xs">{data.facebook}</span>
          </a>
        {/if}

        {#if data.vk && data.vk.length}
          <a
            href="https://vk.com/{data.vk}"
            target="_blank"
            class="flex items-center mr-8 flex items-center justify-center social-link"
          >
            <span class="with-border text-center mr-2 block flex items-center justify-center">
              <i class="fab fa-vk"></i>
            </span>
            <span class="block text-xs">{data.vk}</span>
          </a>
        {/if}

        {#if data.telegram && data.telegram.length}
          <a href="https://t.me/{data.telegram}" target="_blank" class="flex items-center mr-8 social-link">
            <span class="with-border text-center mr-2 block flex items-center justify-center">
              <i class="fab fa-telegram-plane"></i>
            </span>
            <span class="block text-xs">{data.telegram}</span>
          </a>
        {/if}

        {#if data.youtube && data.youtube.length}
          <a href="https://www.youtube.com/{data.youtube}" target="_blank" class="flex items-center mr-8 social-link">
            <span class="with-border text-center mr-2 block flex items-center justify-center">
              <i class="fab fa-youtube"></i>
            </span>
            <span class="block text-xs">{data.youtube}</span>
          </a>
        {/if}

        {#if data.rss && data.rss.length}
          <a href="https://{data.rss}" target="_blank" class="flex items-center mr-8 social-link">
            <span class="with-border text-center mr-2 block flex items-center justify-center">
              <i class="fas fa-rss"></i>
            </span>
            <span class="block text-xs">{data.rss}</span>
          </a>
        {/if}
      </div>
      <div class="">
        {data.bio}
      </div>
    </div>
  </div>
</div>

<div class="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8 p-4 md:px-8 md:py-0">
  {#each posts as post, i}
    <div class="flex flex-col">
      <PostPreview delay="{200}" post="{post}" />
    </div>
  {/each}
</div>

<style>
  .with-border {
    border: 2px solid #000000;
    outline: 0;
    height: 26px;
    width: 26px;
    border-radius: 6px;
    font-size: 14px;
  }

  .author-picture {
    /*width: 33%;*/
  }

  .social-link {
    height: 40px;
  }

  @media (min-width: 425px) {
    .author-picture {
      width: 33%;
    }
  }
</style>
