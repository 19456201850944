<script lang="ts">
  // import { Posts } from '../data/Post';
  import { NavItems } from '../data/TopNav';
  import AppHeader from '../components/AppHeader.svelte';
  import AppFooter from '../components/AppFooter.svelte';
  import PostPreview from '../components/PostPreview.svelte';
  import { onDestroy, onMount, tick } from 'svelte';
  import { link } from 'svelte-navigator';
  import { io } from 'socket.io-client';
  import config from '../config.json';
  import { getPosts } from '../data/API';
  import type { IPostFilter } from '../data/API';
  import type { PostItem } from '../data/Post';

  export let category;
  export let tag;
  export let nav; // merge with category
  export let search;
  export let pageNum: number = 1;
  export let onlyPageNum: number = 0;

  let pageSize = 6;
  let allPagesShown = false;
  let posts: PostItem[] = [];

  const socket = io(config.backend);

  onMount(() => {
    if (location.host !== 'jsdom.ssr') {
      document.documentElement.scrollTop = 0;

      socket.on('refresh posts', () => {
        reload(category, nav, tag, search, pageSize, pageNum);
      });
    } else {
      if (location.host === 'jsdom.ssr' && pageNum > 1) {
        allPagesShown = true;
      }
    }
  });

  onDestroy(() => {
    socket.off('refresh posts');
  });

  let categoryTitle;
  let navTitle;

  const reload = async (
    _category: string,
    _nav: string,
    _tag: string,
    _search: string,
    _pageSize: number,
    _pageNum: number
  ) => {
    const filter: IPostFilter = {};
    if (_category?.length) {
      for (const ni of $NavItems) {
        const found = ni.subItems.find((si) => si.link === _category);
        if (found?.title) {
          filter.category = [found.title];
          categoryTitle = found.title;
          break;
        }
      }
    }
    if (_nav?.length) {
      const foundNav = $NavItems.find((n) => n.link.toLowerCase() === _nav.toLowerCase());
      let navCat = [];
      if (foundNav) {
        navCat = foundNav.subItems.map((si) => si.title);
        navTitle = foundNav.title;
      }
      filter.category = navCat;
    }
    if (_tag?.length) {
      filter.tag = _tag;
    }
    if (_search?.length) {
      filter.search = _search;
    }
    if (location.host === 'jsdom.ssr' && _pageNum > 1) {
    } else {
      filter.limit = _pageSize * _pageNum;
    }

    posts = await getPosts(filter);

    await tick();
    dispatchEvent(new CustomEvent('app-loaded'));
  };

  $: reload(category, nav, tag, search, pageSize, pageNum);

  const morePostsClick = (e) => {
    pageNum++;
    e.preventDefault();
  };
</script>

<svelte:head>
  <title>Skillady.com</title>
</svelte:head>

<AppHeader />

{#if category?.length && categoryTitle?.length}
  <div class="text-5xl md:text-6xl text-center pb-4 pt-4 md:pt-0 md:pb-16 leading-none md:-mt-8">{categoryTitle}</div>
{/if}
{#if nav?.length && navTitle?.length}
  <div class="text-5xl md:text-6xl text-center pb-4 pt-4 md:pt-0 md:pb-16 leading-none md:-mt-8">{navTitle}</div>
{/if}

<div
  style="min-height: 50vh"
  class="lg:container mx-auto grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8 p-4 md:px-8 md:py-0"
>
  <!--class:hidden="{location.host === 'jsdom.ssr'}"-->
  <div class="hidden lg:flex flex-col ">
    {#each posts as post, i}
      {#if i % 3 === 0}
        <PostPreview delay="{200}" post="{post}" />
      {/if}
    {/each}
  </div>

  <div class="hidden lg:flex flex-col">
    {#each posts as post, i}
      {#if i % 3 === 1}
        <PostPreview delay="{400}" post="{post}" />
      {/if}
    {/each}
  </div>

  <div class="hidden lg:flex flex-col">
    {#each posts as post, i}
      {#if i % 3 === 2}
        <PostPreview delay="{600}" post="{post}" />
      {/if}
    {/each}
  </div>

  <div class="hidden sm:flex lg:hidden flex-col">
    {#each posts as post, i}
      {#if i % 2 === 0}
        <PostPreview delay="{200}" post="{post}" />
      {/if}
    {/each}
  </div>

  <div class="hidden sm:flex lg:hidden flex-col">
    {#each posts as post, i}
      {#if i % 2 === 1}
        <PostPreview delay="{400}" post="{post}" />
      {/if}
    {/each}
  </div>

  <div class="flex sm:hidden flex-col">
    {#each posts as post, i}
      <PostPreview delay="{200}" post="{post}" />
    {/each}
  </div>

  {#if !allPagesShown}
    <div class="sm:col-span-2 lg:col-span-3 flex items-center justify-center mb-20">
      <a href="/page/{parseInt(pageNum) + 1}" on:click="{morePostsClick}" class="bg-black text-white px-8 py-2"
        >Еще статьи</a
      >
    </div>
  {/if}
</div>

<AppFooter />
