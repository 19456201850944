<script lang="ts">
  import { navigate } from 'svelte-navigator';

  let hover = false;
  let focused = false;
  let value = '';

  const onKeyDown = (e) => {
    if (e.key === 'Enter' && value.trim().length > 0) {
      navigate(`/search/${value}`);
    }
  };
</script>

<div
  on:mouseenter="{() => (hover = true)}"
  on:mouseleave="{() => (hover = false)}"
  class:border-gray-500="{hover || focused || value}"
  class:active="{hover || focused || value}"
  class="flex-1 hidden lg:flex items-center mx-4 lg:ml-16 xl:ml-32 border border-transparent transition-all duration-300 search-wrapper"
>
  <input
    on:focus="{() => (focused = true)}"
    on:blur="{() => (focused = false)}"
    bind:value
    on:keydown="{onKeyDown}"
    class="flex-1 pl-2"
  />
  <button class="text-xl">
    <i class="fas fa-search"></i>
  </button>
</div>

<style>
  .search-wrapper button {
    transition: all 0.3s ease-in-out;
    height: 34px;
  }

  .search-wrapper.active button {
    padding-right: 1rem;
  }
</style>
