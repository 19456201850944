<script lang="ts">
  import { fade } from 'svelte/transition';
  import { link } from 'svelte-navigator';
  import AppHeader from '../components/AppHeader.svelte';
  import AppFooter from '../components/AppFooter.svelte';
  import { Comments } from '../data/Post';
  import type { PostItem } from '../data/Post';
  import { onDestroy, onMount, tick } from 'svelte';
  import PostComments from '../components/PostComments.svelte';
  import { sideBanner } from '../data/Banners';
  import { getComments, getPosts } from '../data/API';
  import { io } from 'socket.io-client';
  import config from '../config.json';

  export let name;
  let letsClear = true;

  let data: PostItem = {
    id: '',
    name: '',
    title: '',
    picture: '',
    category: '',
    tags: [],
    authorId: '',
    authorName: '',
    datePublished: '',
    shortText: '',
    fullText: '',
    paragraphs: [{ content: '' }],
    isHidden: false,
  };

  let similarPosts: PostItem[] = [];

  const loadData = async (_name) => {
    if (_name?.length) {
      const temp = await getPosts({
        name: _name,
      });
      if (temp.length) {
        data = temp[0];

        similarPosts = await getPosts({
          category: [data.category],
        });

        similarPosts = similarPosts.filter((p) => p.id !== data.id);

        await tick();
        dispatchEvent(new CustomEvent('app-loaded'));
      }
    }
  };

  $: loadData(name);

  const socket = io(config.backend);

  onMount(async () => {
    if (location.host !== 'jsdom.ssr') {
      document.documentElement.scrollTop = 0;

      socket.on('refresh posts', () => {
        loadData(name);
      });

      socket.on('refresh comments', async () => {
        if (data?.id) {
          $Comments = await getComments(data.id);
        }
      });
    }
  });

  onDestroy(() => {
    socket.off('refresh posts');
    socket.off('refresh comments');
  });

  const clearComments = async () => {
    letsClear = true;
  };

  const getFirstLetter = (html) => {
    let skip: boolean = false;
    for (let i = 0; i < html.length; i++) {
      if (html[i] === '<') {
        skip = true;
      }
      if (!skip) {
        return html[i];
      }
      if (html[i] === '>') {
        skip = false;
      }
    }
    return '';
  };
</script>

<svelte:head>
  <title>{data.title}</title>
  <meta property="og:title" content="{data.title}" />
  <meta property="og:site_name" content="Skillady - Женский журнал о стиле жизни" />
  <meta property="og:url" content="{`https://skillady.com/post/${data.name}`}" />
  <meta property="og:description" content="{data.shortText}" />
  <meta property="og:image" content="{data.picture}" />
</svelte:head>

<AppHeader />

<div class="container mx-auto px-4 lg:px-8 pb-32">
  <div class="flex">
    <div class="flex-1 px-0 lg:px-24 lg:pr-32" in:fade="{{ duration: 500 }}">
      <div class="flex text-sm mb-2 md:m-0">
        <div class="">{data.authorName}</div>
        <div class="mx-2">/</div>
        <div>{data.datePublished}</div>
        <div class="mx-2">/</div>
        <div class="text-gray-500">{data.category}</div>
      </div>

      <h1 class="title text-4xl md:text-5xl leading-tight mb-2 md:mb-8">
        {data.title}
      </h1>

      {#each data.paragraphs as paragraph, index}
        <div class="paragraph">
          {#if paragraph.imageUrl && paragraph.imageUrl.length}
            {#if paragraph.imagePosition === 'wide'}
              <div class="lg:-mx-24 flex flex-col items-center justify-center pt-8 pb-8 overflow-hidden">
                <img class="object-cover max-w-full" alt="" src="{paragraph.imageUrl}" />
                {#if paragraph.imageCaption && paragraph.imageCaption.length}
                  <label class="mt-3">{paragraph.imageCaption}</label>
                {/if}
              </div>

              {#if paragraph.type === 'h1' || paragraph.type === 'h2'}
                <h2
                  class:text-3xl="{paragraph.type === 'h1'}"
                  class:text-2xl="{paragraph.type === 'h2'}"
                  class="text-gray-900 pb-8 text-lg leading-normal relative"
                >
                  {@html paragraph.content}
                  <div class="drop-cap ml-8 lg:ml-0">
                    {getFirstLetter(paragraph.content)}
                  </div>
                </h2>
              {:else}
                <div class="text-gray-900 pb-8 text-lg leading-normal relative">
                  {@html paragraph.content}
                </div>
              {/if}
            {:else if paragraph.imagePosition === 'center'}
              <div class="flex items-center justify-center flex-col pt-8 pb-8">
                <img class="object-cover max-w-full" alt="" src="{paragraph.imageUrl}" />
                {#if paragraph.imageCaption && paragraph.imageCaption.length}
                  <label class="mt-3">{paragraph.imageCaption}</label>
                {/if}
              </div>

              {#if paragraph.type === 'h1' || paragraph.type === 'h2'}
                <h2
                  class:text-3xl="{paragraph.type === 'h1'}"
                  class:text-2xl="{paragraph.type === 'h2'}"
                  class="text-gray-900 pb-8 text-lg leading-normal relative"
                >
                  {@html paragraph.content}
                  <div class="drop-cap ml-8 lg:ml-0">
                    {getFirstLetter(paragraph.content)}
                  </div>
                </h2>
              {:else}
                <div class="text-gray-900 pb-8 text-lg leading-normal relative">
                  {@html paragraph.content}
                </div>
              {/if}
            {:else if paragraph.imagePosition === 'left'}
              <div class="flex lg:hidden flex-col items-center justify-center pt-8 pb-8">
                <img class="object-cover max-w-full" alt="" src="{paragraph.imageUrl}" />
                {#if paragraph.imageCaption && paragraph.imageCaption.length}
                  <label class="mt-3">{paragraph.imageCaption}</label>
                {/if}
              </div>

              <div class="lg:-ml-24 flex py-8">
                <div class="flex-1 hidden lg:flex flex-col justify-center pr-4">
                  <img class="object-cover max-w-full" alt="" src="{paragraph.imageUrl}" />
                  {#if paragraph.imageCaption && paragraph.imageCaption.length}
                    <label class="mt-3">{paragraph.imageCaption}</label>
                  {/if}
                </div>

                {#if paragraph.type === 'h1' || paragraph.type === 'h2'}
                  <h2
                    class:text-3xl="{paragraph.type === 'h1'}"
                    class:text-2xl="{paragraph.type === 'h2'}"
                    class="lg:pl-4 text-gray-900 flex-1 text-lg leading-normal relative"
                  >
                    {@html paragraph.content}
                    <div class="drop-cap ml-8 lg:ml-0">
                      {getFirstLetter(paragraph.content)}
                    </div>
                  </h2>
                {:else}
                  <div class="lg:pl-4 text-gray-900 flex-1 text-lg leading-normal relative">
                    {@html paragraph.content}
                  </div>
                {/if}
              </div>
            {:else if paragraph.imagePosition === 'right'}
              <div class="flex flex-col lg:hidden items-center justify-center pt-8 pb-8">
                <img class="object-cover max-w-full" alt="" src="{paragraph.imageUrl}" />
                {#if paragraph.imageCaption && paragraph.imageCaption.length}
                  <label class="mt-3">{paragraph.imageCaption}</label>
                {/if}
              </div>

              <div class="lg:-mr-24 flex py-8">
                {#if paragraph.type === 'h1' || paragraph.type === 'h2'}
                  <h2
                    class:text-3xl="{paragraph.type === 'h1'}"
                    class:text-2xl="{paragraph.type === 'h2'}"
                    class="lg:pr-4 text-gray-900 flex-1 text-lg leading-normal relative"
                  >
                    {@html paragraph.content}
                    <div class="drop-cap ml-8 lg:ml-0">
                      {getFirstLetter(paragraph.content)}
                    </div>
                  </h2>
                {:else}
                  <div class="lg:pr-4 text-gray-900 flex-1 text-lg leading-normal relative">
                    {@html paragraph.content}
                  </div>
                {/if}

                <div class="flex-1 hidden lg:flex flex-col justify-center pl-4">
                  <img class="object-cover max-w-full" alt="" src="{paragraph.imageUrl}" />
                  {#if paragraph.imageCaption && paragraph.imageCaption.length}
                    <label class="mt-3">{paragraph.imageCaption}</label>
                  {/if}
                </div>
              </div>
            {/if}
          {:else if paragraph.type === 'quote'}
            <div
              class="flex flex-col items-center text-2xl lg:text-3xl text-center"
              style="font-family: 'Playfair Display', serif"
            >
              <div class="my-8 bg-gray-500 w-56 relative text-lg" style="height: 1px">
                <i class="quote-icon fas fa-quote-left"></i>
              </div>
              {@html paragraph.content}
              <div class="my-8 bg-gray-500 w-56 relative text-lg" style="height: 1px">
                <i class="quote-icon fas fa-quote-right"></i>
              </div>
            </div>
          {:else if paragraph.type === 'h1' || paragraph.type === 'h2'}
            <h2
              class:text-3xl="{paragraph.type === 'h1'}"
              class:text-2xl="{paragraph.type === 'h2'}"
              class="py-8 text-gray-900 text-lg leading-normal relative"
            >
              {@html paragraph.content}
              <div class="drop-cap ml-8 lg:ml-0">
                {getFirstLetter(paragraph.content)}
              </div>
            </h2>
          {:else}
            <div class="py-8 text-gray-900 text-lg leading-normal relative">
              {@html paragraph.content}
            </div>
          {/if}
        </div>
      {/each}

      <div class="py-8">
        <div class="flex flex-wrap">
          {#each data.tags as tag}
            {#if tag.length}
              <a use:link href="{`/tag/${tag}`}" class="mr-4 mb-4 px-4 py-1 bg-black text-white text-sm">{tag}</a>
            {/if}
          {/each}
        </div>
      </div>

      <div class="py-4 text-2xl">Поделиться:</div>
      <div class="flex pb-8">
        <a
          target="_blank"
          href="{`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}"
          class="py-1 flex items-center mr-4"
        >
          <img src="/img/fb.png" alt="" style="width: 32px; height: 32px;" />
        </a>

        <a
          target="_blank"
          href="{`https://twitter.com/intent/tweet?text=${'читайте на skillady ' + location.href}`}"
          class="py-1 flex items-center mr-4"
        >
          <img src="/img/tw.png" alt="" style="width: 32px; height: 32px;" />
        </a>

        <a target="_blank" href="{`https://vk.com/share.php?url=${location.href}`}" class="py-1 flex items-center mr-4">
          <img src="/img/vk.png" alt="" style="width: 32px; height: 32px;" />
        </a>
      </div>

      <PostComments postId="{data.id}" comments="{$Comments}" />
    </div>

    <div class="hidden lg:flex side-bar flex-col px-8" in:fade="{{ duration: 500, delay: 250 }}">
      <div class="py-4 text-2xl">Поделиться:</div>
      <div class="flex pb-8 border border-solid border-gray-500 border-t-0 border-l-0 border-r-0">
        <a
          target="_blank"
          href="{`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}"
          class="py-1 flex items-center mr-4"
        >
          <img src="/img/fb.png" alt="" style="width: 32px; height: 32px;" />
        </a>

        <a
          target="_blank"
          href="{`https://twitter.com/intent/tweet?text=${'читайте на skillady ' + location.href}`}"
          class="py-1 flex items-center mr-4"
        >
          <img src="/img/tw.png" alt="" style="width: 32px; height: 32px;" />
        </a>

        <a target="_blank" href="{`https://vk.com/share.php?url=${location.href}`}" class="py-1 flex items-center mr-4">
          <img src="/img/vk.png" alt="" style="width: 32px; height: 32px;" />
        </a>
      </div>

      {#each similarPosts as _post, _index}
        {#if _index === 0}
          <div class="py-8 text-2xl">Другие статьи:</div>
        {/if}
        {#if _index < 3}
          <div class="mb-6">
            <a on:click="{clearComments}" use:link href="{`/post/${_post.name}`}" class="hover:underline">
              {_post.title}
            </a>
            <div class="text-gray-500 italic">{_post.datePublished}</div>
          </div>
        {/if}
      {/each}

      {#if $sideBanner && $sideBanner.imgSrc && $sideBanner.imgSrc.length}
        <div class="bg-black text-white flex flex-col items-center justify-center text-xl mb-8">
          <a href="{$sideBanner.url}" target="_blank" class="w-full text-white banner overflow-hidden">
            <img src="{$sideBanner.imgSrc}" />
          </a>
        </div>
      {/if}

      <div class="border border-solid border-gray-500 border-t-0 border-l-0 border-r-0 py-4"></div>

      {#if $Comments.filter((c) => c.postId === data.id).length > 0}
        <div class="py-8 text-xl">Недавние комментарии:</div>
      {/if}

      <div>
        {#each $Comments.filter((c) => c.postId === data.id) as c, i}
          {#if i < 3}
            <div class="mb-4 text-sm">
              {c.name}&nbsp;&nbsp;<span class="text-gray-400">написал:</span>&nbsp;&nbsp;{c.text}
            </div>
          {/if}
        {/each}
      </div>
    </div>
  </div>
</div>

<AppFooter />

<style>
  .title {
    font-family: 'Playfair Display', serif;
  }

  .side-bar {
    width: 350px;
  }

  .drop-cap {
    position: absolute;
    font-size: 15rem;
    color: #eee;
    left: -5rem;
    line-height: 1;
    top: -2rem;
    z-index: -1;
  }

  .quote-icon {
    position: absolute;
    top: -8px;
    background: #ffffff;
    width: 50px;
    margin: auto;
    left: 0;
    right: 0;
  }

  @media (min-width: 425px) {
    .drop-cap {
      top: -3rem;
    }
  }
</style>
