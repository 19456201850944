<script lang="ts">
  import { onMount, tick } from 'svelte';
  import { Router, Route } from 'svelte-navigator';
  import { tweened } from 'svelte/motion';
  import { cubicInOut } from 'svelte/easing';
  import { fade } from 'svelte/transition';

  import ListOfPosts from './pages/ListOfPosts.svelte';
  import ListOfPostsByPage from './pages/ListOfPostsByPage.svelte';
  import Post from './pages/Post.svelte';

  import AdminHomePage from './pages/AdminHomePage.svelte';
  import { Posts, Comments } from './data/Post';
  import { getPosts, getAuthors, getStaticData, getComments } from './data/API';
  import { NavItems } from './data/TopNav';
  import { SocialLinks } from './data/SocialLinks';
  import { IGPosts } from './data/IGProfile';
  import { Authors } from './data/Author';
  import InfoPages from './pages/InfoPages.svelte';

  import NotFound from './pages/NotFound.svelte';
  import Categories from './pages/Categories.svelte';
  import NavPosts from './pages/NavPosts.svelte';
  import TagSearch from './pages/TagSearch.svelte';
  import Search from './pages/Search.svelte';

  import { topBanner, sideBanner } from './data/Banners';
  import { io } from 'socket.io-client';
  import config from './config.json';

  onMount(async () => {
    if (location.host !== 'jsdom.ssr') {
      const socket = io(config.backend);

      socket.on('refresh authors', async () => {
        $Authors = await getAuthors();
      });

      socket.on('refresh static data', async () => {
        const staticData = await getStaticData();
        $NavItems = staticData['navigation'].items;

        const topBannerResp = staticData['topBanner'];
        const sideBannerResp = staticData['sideBanner'];

        $topBanner = {
          url: topBannerResp.url,
          imgSrc: topBannerResp.imgSrc,
          mobileImgSrc: topBannerResp.mobileImgSrc,
        };
        $sideBanner = { url: sideBannerResp.url, imgSrc: sideBannerResp.imgSrc };

        $IGPosts = staticData['ig-posts'].items;

        $SocialLinks = staticData['social'].items;
      });
    }

    if (location.host === 'jsdom.ssr') {
      $Authors = await getAuthors();
      const staticData = await getStaticData();
      $NavItems = staticData['navigation'].items;

      const topBannerResp = staticData['topBanner'];
      const sideBannerResp = staticData['sideBanner'];

      $topBanner = {
        url: topBannerResp.url,
        imgSrc: topBannerResp.imgSrc,
        mobileImgSrc: topBannerResp.mobileImgSrc,
      };
      $sideBanner = { url: sideBannerResp.url, imgSrc: sideBannerResp.imgSrc };

      $IGPosts = staticData['ig-posts'].items;

      $SocialLinks = staticData['social'].items;
    }
  });

  const scrollProgress = tweened(0, {
    easing: cubicInOut,
  });

  let active: boolean = false;
  let showUpButton: boolean = false;

  const onUpClick = () => {
    active = true;
    scrollProgress.set(0, { duration: 1000 });
  };

  const onScroll = (e) => {
    if (!active) {
      scrollProgress.set(document.documentElement.scrollTop, { duration: 0 });
    }
    if (document.documentElement.scrollTop > 10) {
      showUpButton = true;
    } else {
      showUpButton = false;
    }
  };

  const onWheel = (e) => {
    active = false;
  };

  function smoothScroll(v) {
    if (active) {
      document.documentElement.scrollTop = v;
    }
  }

  $: smoothScroll($scrollProgress);
</script>

<svelte:window on:scroll="{onScroll}" on:wheel="{onWheel}" />

<Router>
  <Route path="/admin-tools/*" component="{AdminHomePage}" />
  <Route path="/post/:name" component="{Post}" />
  <Route path="/info/*" component="{InfoPages}" />
  <Route path="/category/:cat" component="{Categories}" />
  <Route path="/category/:cat/page/:pageNum" component="{Categories}" />
  <Route path="/nav/:nav" component="{NavPosts}" />
  <Route path="/tag/:tag" component="{TagSearch}" />
  <Route path="/page/:pageNum" component="{ListOfPostsByPage}" />
  <Route path="/search/:q" component="{Search}" />
  <Route path="/">
    <ListOfPosts />
  </Route>
  <Route path="/error" component="{NotFound}" />
  <Route component="{NotFound}" />
</Router>

{#if showUpButton}
  <div
    transition:fade="{{ duration: 300 }}"
    on:click="{onUpClick}"
    class="cursor-pointer fixed bottom-0 right-0 mb-4 mr-4 bg-gray-400 text-white w-10 h-10 flex items-center justify-center text-2xl"
  >
    <i class="fas fa-arrow-up"></i>
  </div>
{/if}

<style global>
  .grecaptcha-badge {
    visibility: hidden;
  }

  .paragraph a {
    color: rgb(0, 100, 200);
    text-decoration: none;
  }

  .paragraph a:hover {
    text-decoration: underline;
  }

  .paragraph a:visited {
    color: rgb(0, 80, 160);
  }

  input,
  input:focus,
  button,
  button:focus,
  textarea,
  textarea:focus {
    outline: none;
  }
</style>
