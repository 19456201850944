<script lang="ts">
  import ListOfPosts from './ListOfPosts.svelte';
  import { NavItems } from '../data/TopNav';

  export let nav;
  export let pageNum;
</script>

{#if $NavItems?.length}
  <ListOfPosts nav="{nav}" pageNum="{pageNum}" />
{/if}
