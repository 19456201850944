<script lang="ts">
  import { Authors } from '../data/Author';
  import { onMount } from 'svelte';
  import { link, navigate } from 'svelte-navigator';

  onMount(() => {
    if (location.host !== 'jsdom.ssr') {
      document.documentElement.scrollTop = 0;
    }
    setTimeout(() => {
      dispatchEvent(new CustomEvent('app-loaded'));
    }, 100);
  });
</script>

<div class="container mx-auto px-8 pb-32">
  <h1 class="text-5xl md:text-6xl text-center pb-8">Колумнисты</h1>

  <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-10">
    {#each $Authors as author}
      <div class="flex flex-col items-center">
        <div
          on:click="{() => navigate('/info/author/' + author.id)}"
          class="overflow-hidden flex items-center justify-center mb-2 hover:opacity-75 cursor-pointer author-picture"
        >
          <img alt="" src="{author.picture}" class="max-w-full object-contain" />
        </div>

        <div class="text-center text-lg mb-2">
          <a use:link href="/info/author/{author.id}">{author.name}</a>
        </div>
        <div class="text-center text-sm mb-2">{author.title}</div>

        <div class="flex items-center justify-center text-2xl mb-2">
          {#if author.instagram && author.instagram.length}
            <a href="https://instagram.com/{author.instagram}" target="_blank" class="text-center text-3xl mx-1">
              <i class="fab fa-instagram"></i>
            </a>
          {/if}

          {#if author.facebook && author.facebook.length}
            <a
              href="https://www.facebook.com/{author.facebook}"
              target="_blank"
              class="with-border text-center mx-1 flex items-center justify-center"
            >
              <i class="fab fa-facebook-f"></i>
            </a>
          {/if}

          {#if author.vk && author.vk.length}
            <a
              href="https://vk.com/{author.vk}"
              target="_blank"
              class="with-border text-center mx-1 flex items-center justify-center"
            >
              <i class="fab fa-vk"></i>
            </a>
          {/if}

          {#if author.telegram && author.telegram.length}
            <a
              href="https://t.me/{author.telegram}"
              target="_blank"
              class="with-border text-center mx-1 flex items-center justify-center"
            >
              <i class="fab fa-telegram-plane"></i>
            </a>
          {/if}

          {#if author.youtube && author.youtube.length}
            <a
              href="https://www.youtube.com/{author.youtube}"
              target="_blank"
              class="with-border text-center mx-1 flex items-center justify-center"
            >
              <i class="fab fa-youtube"></i>
            </a>
          {/if}

          {#if author.rss && author.rss.length}
            <a
              href="https://{author.rss}"
              target="_blank"
              class="with-border text-center mx-1 flex items-center justify-center"
            >
              <i class="fas fa-rss"></i>
            </a>
          {/if}
        </div>

        <a
          href="{author.web}"
          target="_blank"
          class="block underline text-center text-sm overflow-hidden"
          style="text-overflow: ellipsis; max-width: 100%">{author.web.replace(/^https?:\/\//, '')}</a
        >
      </div>
    {/each}
  </div>
</div>

<style>
  .with-border {
    border: 2px solid #000000;
    outline: 0;
    height: 26px;
    width: 26px;
    border-radius: 6px;
    font-size: 14px;
  }

  .author-picture {
    width: 150px;
    height: 150px;
  }

  @media (min-width: 425px) {
    .author-picture {
      width: 200px;
      height: 200px;
    }
  }
</style>
