<script lang="ts">
  import { Authors } from '../data/Author';
  import type { Author } from '../data/Author';
  import { deleteAuthor, saveAuthor } from '../data/API';
  import { navigate } from 'svelte-navigator';
  import axios from 'axios';
  import config from '../config.json';

  let data: Author = {
    id: '',
    name: '',
    title: '',
    picture: '',
    bio: '',
    web: '',
    facebook: '',
    instagram: '',
    rss: '',
    telegram: '',
    vk: '',
    youtube: '',
  };

  const loadData = (_authors, _id) => {
    if (_authors.length && _id && _id.length) {
      let res = _authors.filter((p) => p.id === _id);
      if (res.length === 1) {
        data = { ...res[0] };
      }
    }
  };

  export let id: string;

  $: loadData($Authors, id);

  const blur = async () => {
    if (
      !data.name?.length &&
      !data.title?.length &&
      !data.picture?.length &&
      !data.bio?.length &&
      !data.web?.length &&
      !data.facebook?.length &&
      !data.instagram?.length &&
      !data.rss?.length &&
      !data.telegram?.length &&
      !data.vk?.length &&
      !data.youtube?.length
    )
      return;
    const newId = await saveAuthor({ ...data, id });
    if (id === 'new') {
      navigate(`/admin-tools/author/${newId}`);
    }
  };

  const fileSelect = async (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;
    const file = files[0];

    const formData = new FormData();
    formData.append('image_file', file);
    const resp = await axios.put(`${config.backend}/upload`, formData, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    });

    data = { ...data, picture: `${config.cdn}/${resp.data}` };

    await blur();
  };

  const onDeletePicture = async () => {
    data = { ...data };
    delete data.picture;
    await blur();
  };

  const onDeleteAuthor = async () => {
    await deleteAuthor(id);
  };
</script>

<div class="flex-1 lg:container mx-auto px-4 h-screen overflow-auto text-gray-900">
  <div class="py-8 text-center text-2xl">Колумнист</div>

  <div class="flex flex-col mb-8">
    <label for="name">Имя</label>
    <input id="name" class="p-2 border-gray-900 border" bind:value="{data.name}" on:blur="{blur}" />
  </div>

  <div class="flex flex-col mb-8">
    <label for="title">Профессия</label>
    <input id="title" class="p-2 border-gray-900 border" bind:value="{data.title}" on:blur="{blur}" />
  </div>

  <div class="flex flex-col mb-8">
    <label for="bio">Биография</label>
    <textarea id="bio" class="p-2 border-gray-900 border" bind:value="{data.bio}" on:blur="{blur}" rows="5"></textarea>
  </div>

  <div>
    <button class="h-20 py-2 cursor-pointer text-gray-900 hover:text-blue-700 relative flex items-center">
      <input
        type="file"
        class="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
        on:change="{(e) => fileSelect(e)}"
      />
      <span class="mr-4">Загрузить фото</span>
      <i class="far fa-image text-2xl"></i>
    </button>
  </div>

  <div class="h-40 mb-8">
    <img class="object-cover max-h-full" src="{data.picture}" alt="" />
  </div>

  {#if data.picture}
    <div class="mb-8">
      <button
        class="h-20 py-2 cursor-pointer text-gray-900 hover:text-blue-700 relative flex items-center"
        on:click="{onDeletePicture}"
      >
        <span class="mr-4">Удалить</span>
        <i class="far fa-trash-alt"></i>
      </button>
    </div>
  {/if}

  <div class="flex flex-col mb-8">
    <label for="web">Web</label>
    <input id="web" class="p-2 border-gray-900 border" bind:value="{data.web}" on:blur="{blur}" />
  </div>

  <div class="flex flex-col mb-8">
    <label for="facebook">Facebook</label>
    <input id="facebook" class="p-2 border-gray-900 border" bind:value="{data.facebook}" on:blur="{blur}" />
  </div>

  <div class="flex flex-col mb-8">
    <label for="instagram">Instagram</label>
    <input id="instagram" class="p-2 border-gray-900 border" bind:value="{data.instagram}" on:blur="{blur}" />
  </div>

  <div class="flex flex-col mb-8">
    <label for="rss">Blog</label>
    <input id="rss" class="p-2 border-gray-900 border" bind:value="{data.rss}" on:blur="{blur}" />
  </div>

  <div class="flex flex-col mb-8">
    <label for="telegram">Telegram</label>
    <input id="telegram" class="p-2 border-gray-900 border" bind:value="{data.telegram}" on:blur="{blur}" />
  </div>

  <div class="flex flex-col mb-8">
    <label for="vk">VK</label>
    <input id="vk" class="p-2 border-gray-900 border" bind:value="{data.vk}" on:blur="{blur}" />
  </div>

  <div class="flex flex-col mb-8">
    <label for="youtube">Youtube</label>
    <input id="youtube" class="p-2 border-gray-900 border" bind:value="{data.youtube}" on:blur="{blur}" />
  </div>
  {#if data.id}
    <div class="flex justify-center mb-8">
      <button
        on:click="{onDeleteAuthor}"
        class="bg-red-500 flex items-center justify-center px-8 py-2 rounded text-white hover:bg-red-600"
      >
        <i class="far fa-trash-alt mr-2 text-xl"></i>
        <span>Удалить колумниста</span>
      </button>
    </div>
  {/if}
</div>
