<script lang="ts">
  import { IGPosts } from '../data/IGProfile';
  import { saveIGPosts } from '../data/API';
  import axios from 'axios';
  import config from '../config.json';

  const addNewIGPost = () => {
    $IGPosts = [
      ...$IGPosts,
      {
        link: '',
        picture: '',
      },
    ];
  };

  const fileSelect = async (e, index) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;

    const file = files[0];

    const formData = new FormData();
    formData.append('image_file', file);
    const resp = await axios.put(`${config.backend}/upload`, formData, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    });

    const temp = $IGPosts;
    temp[index].picture = `${config.cdn}/${resp.data}`;
    $IGPosts = [...temp];
    await saveIGPosts([...temp]);
  };

  const inputBlur = async (e, index) => {
    const temp = $IGPosts;
    temp[index].link = e.target.value;
    $IGPosts = [...temp];
    await saveIGPosts([...temp]);
  };

  const onDeleteClick = async (index) => {
    const temp = $IGPosts;
    temp.splice(index, 1);
    $IGPosts = temp;
    await saveIGPosts([...temp]);
  };
</script>

<div class="flex-1 pb-12">
  <div class="py-8 text-center text-2xl">Настройка ссылок Instagram</div>
  <button on:click="{addNewIGPost}" class="px-4 py-1 bg-green-500 text-white mt-8 mb-8 ml-8">добавить</button>
  <div class="flex flex-col flex-col-reverse pb-12">
    {#each $IGPosts as item, index}
      <div class="bg-blue-100 px-4 py-6">
        <div class="relative mb-4">
          <div>Изображение:</div>
          {#if item.picture && item.picture.length}
            <img src="{item.picture}" style="max-height: 180px" />
          {:else}
            <div class="text-3xl flex items-center justify-center p-4">
              <i class="fas fa-cloud-upload-alt"></i>
            </div>
            <input
              type="file"
              class="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
              on:change="{(e) => fileSelect(e, index)}"
            />
          {/if}
        </div>
        <div>
          <div>Ссылка:</div>
          <input class="px-2 py-1 w-full" bind:value="{item.link}" on:blur="{(e) => inputBlur(e, index)}" />
        </div>
        <div>
          <button on:click="{() => onDeleteClick(index)}" class="px-2 py-1 bg-red-100 mt-2 border">Удалить</button>
        </div>
      </div>
    {/each}
  </div>
</div>
