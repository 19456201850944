import axios from 'axios';
import config from '../config.json';
import { navigate } from 'svelte-navigator';
const ssrHost = 'jsdom.ssr';
/**********************************
 * Load static data: Nav Data, static articles
 **********************************/
export const getStaticData = async () => {
    const resp = await axios.get(`${config.backend}/static-data`);
    let result = {};
    resp.data.forEach((item) => {
        result[item.id] = Object.assign({}, JSON.parse(item.data));
    });
    return result;
};
export const saveTopNavData = async (data) => {
    await axios.post(`${config.backend}/static-data`, {
        id: 'navigation',
        data: JSON.stringify({ items: [...data] }),
    }, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } });
};
export const saveIGPosts = async (data) => {
    await axios.post(`${config.backend}/static-data`, {
        id: 'ig-posts',
        data: JSON.stringify({ items: [...data] }),
    }, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } });
};
export const saveSocial = async (data) => {
    await axios.post(`${config.backend}/static-data`, {
        id: 'social',
        data: JSON.stringify({ items: [...data] }),
    }, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } });
};
export const getPosts = async (filter) => {
    const params = {};
    if (filter.tag) {
        params.tag = filter.tag;
    }
    if (filter.category) {
        params.category = filter.category;
    }
    if (filter.search) {
        params.search = filter.search;
    }
    if (filter.authorId) {
        params.authorId = filter.authorId;
    }
    if (filter.name) {
        params.name = filter.name;
    }
    if (filter.id) {
        params.id = filter.id;
    }
    if (filter.limit) {
        params.limit = filter.limit;
    }
    if (filter.isHidden) {
        params.isHidden = true;
    }
    const resp = await axios.post(`${config.backend}/posts-get`, Object.assign({}, params));
    const result = [];
    resp.data.forEach((item) => {
        result.push(Object.assign(Object.assign({}, item.data), { id: item.id }));
    });
    return result;
};
export const savePost = async (data) => {
    if (data.id === 'new') {
        const resp = await axios.put(`${config.backend}/post`, {
            title: data.title,
            name: data.name,
            authorId: data.authorId,
            authorName: data.authorName,
            category: data.category,
            tags: JSON.stringify(data.tags),
            datePublished: data.datePublished,
            shortText: data.shortText,
            picture: data.picture,
            fullText: data.fullText,
            paragraphs: JSON.stringify(data.paragraphs),
            isHidden: data.isHidden,
        }, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } });
        return resp.data.id;
    }
    else {
        const resp = await axios.post(`${config.backend}/post`, {
            id: data.id,
            title: data.title,
            name: data.name,
            authorId: data.authorId,
            authorName: data.authorName,
            category: data.category,
            tags: JSON.stringify(data.tags),
            datePublished: data.datePublished,
            shortText: data.shortText,
            picture: data.picture,
            fullText: data.fullText,
            paragraphs: JSON.stringify(data.paragraphs),
            isHidden: data.isHidden,
        }, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } });
        return resp.data.id;
    }
};
export const deletePost = async (id) => {
    await axios.delete(`${config.backend}/post`, {
        data: {
            id: id,
        },
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    navigate('/admin-tools/articles');
};
/**********************************
 * Comments related
 **********************************/
export const newComment = async (token, postId, text, name, phone) => {
    await axios.put(`${config.backend}/comment`, {
        postId,
        text,
        name,
        phone,
        token,
        date: new Date().toISOString(),
    });
};
export const getComments = async (postId) => {
    const resp = await axios.get(`${config.backend}/comments`, {
        params: {
            postId: postId,
        },
    });
    const result = [];
    resp.data.forEach((item) => {
        result.push(Object.assign(Object.assign({}, item.data), { id: item.id }));
    });
    result.sort((a, b) => (a.date < b.date ? 1 : a.date > b.date ? -1 : 0));
    return result;
};
export const getAllComments = async () => {
    const resp = await axios.get(`${config.backend}/comments-all`, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    return resp.data;
};
export const deleteComment = async (id) => {
    const resp = await axios.delete(`${config.backend}/comment`, {
        data: {
            id: id,
        },
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    return resp.data;
};
/**********************************
 * Initial variables
 **********************************/
export const getAuthors = async () => {
    const resp = await axios.get(`${config.backend}/authors`);
    const result = [];
    resp.data.forEach((item) => {
        result.push(Object.assign(Object.assign({}, JSON.parse(item.data)), { id: item.id }));
    });
    result.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
    return result;
};
/**********************************
 * Authors related
 **********************************/
export const saveAuthor = async (data) => {
    if (data.id === 'new') {
        const resp = await axios.put(`${config.backend}/author`, {
            name: data.name,
            title: data.title,
            picture: data.picture,
            bio: data.bio,
            web: data.web,
            facebook: data.facebook,
            instagram: data.instagram,
            rss: data.rss,
            telegram: data.telegram,
            vk: data.vk,
            youtube: data.youtube,
        }, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } });
        return resp.data.id;
    }
    else {
        const resp = await axios.post(`${config.backend}/author`, {
            id: data.id,
            name: data.name,
            title: data.title,
            picture: data.picture,
            bio: data.bio,
            web: data.web,
            facebook: data.facebook,
            instagram: data.instagram,
            rss: data.rss,
            telegram: data.telegram,
            vk: data.vk,
            youtube: data.youtube,
        }, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } });
        return resp.data.id;
    }
};
export const deleteAuthor = async (id) => {
    await axios.delete(`${config.backend}/author`, {
        data: {
            id: id,
        },
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    navigate('/admin-tools/authors');
};
/**
 * Subscription related
 * */
export const newSubscriber = async (email) => {
    await axios.put(`${config.backend}/subscriber`, {
        email,
    });
};
/**
 * Banners related
 * */
export const updateTopBanner = async (url, imgSrc, mobileImgSrc) => {
    await axios.post(`${config.backend}/static-data`, {
        id: 'topBanner',
        data: JSON.stringify({ url, imgSrc, mobileImgSrc }),
    }, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } });
};
export const updateSideBanner = async (url, imgSrc) => {
    await axios.post(`${config.backend}/static-data`, {
        id: 'sideBanner',
        data: JSON.stringify({ url, imgSrc }),
    }, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } });
};
/**
 * Auth
 * */
export const auth = async (username, password) => {
    const resp = await axios.post(`${config.backend}/user-auth`, {
        username,
        password,
    });
    return resp.data;
};
export const checkToken = async (token) => {
    const resp = await axios.post(`${config.backend}/check-token`, {
        token,
    });
    return resp.data;
};
