<script lang="ts">
  import { fade } from 'svelte/transition';
  import { createEventDispatcher } from 'svelte';
  import { newSubscriber } from '../data/API';

  const dispatch = createEventDispatcher();

  let value;

  const onBackdropClick = () => {
    dispatch('close');
  };

  const onSubscribeClick = async () => {
    await newSubscriber(value);
    dispatch('close');
  };
</script>

<div
  transition:fade="{{ duration: 250 }}"
  on:click="{onBackdropClick}"
  class="bg-gray-100 fixed inset-0 z-20 opacity-75"
></div>

<div transition:fade="{{ duration: 250 }}" class="form bg-white fixed inset-0 m-auto z-20 shadow-md">
  <div class="relative w-full h-full flex flex-col items-center justify-center">
    <div
      class="absolute right-0 top-0 pt-12 pr-4 md:p-4 text-2xl text-yellow-800 cursor-pointer"
      on:click="{onBackdropClick}"
    >
      <i class="fa fa-times"></i>
    </div>

    <div class="text-xl mb-6">Подпишитесь на "Skillady"</div>
    <div class="mb-6 w-full flex justify-center">
      <input bind:value class="w-3/4 py-3 px-4  border-gray-400 text-center" placeholder="Адрес электронной почты" />
    </div>
    <div class="mb-6 w-3/4 text-center">
      <input id="agree" type="checkbox" class="mr-2" />
      <label for="agree">
        Ознакомлен и согласен с <a href="/info/policy" target="_blank" class="underline">политикой конфиденциальности.</a
        >
      </label>
    </div>
    <div>
      <button on:click="{onSubscribeClick}" class="bg-yellow-800 px-10 py-3 text-white hover:bg-yellow-900"
        >Подписаться</button
      >
    </div>
  </div>
</div>

<style>
  .form {
    width: 100vw;
    height: 100vh;
  }
  input {
    border-radius: 0;
    background: none;
    border: 1px solid;
  }

  @media (min-width: 768px) {
    .form {
      width: 600px;
      height: 400px;
    }
  }
</style>
