<script lang="ts">
  import IGBlock from './IGBlock.svelte';
  import BottomNav from './BottomNav.svelte';
  import Footer from './Footer.svelte';
</script>

<IGBlock />
<div class="lg:container mx-auto px-8 text-block text-5xl">Текстовый блок</div>
<BottomNav />
<Footer />

<style>
  .text-block {
    display: none;
  }
</style>
