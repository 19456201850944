<script lang="ts">
  import { link } from 'svelte-navigator';
  import { NavItems } from '../data/TopNav';
  import AppLogo from './AppLogo.svelte';

  const fixedItems = [
    {
      title: 'Разделы',
      subItems: [
        {
          title: 'Главная',
          link: '/',
        },
        {
          title: 'Об издании',
          link: '/info/about',
        },
        {
          title: 'Стать автором',
          link: '/info/become-author',
        },
        {
          title: 'Колумнисты',
          link: '/info/authors',
        },
        {
          title: 'Редакция',
          link: '/info/contacts',
        },
        {
          title: 'Соглашение',
          link: '/info/agreement',
        },
        {
          title: 'Политика',
          link: '/info/policy',
        },
      ],
    },
  ];
</script>

<div class="hidden lg:block lg:container mx-auto px-8">
  <div class="flex justify-between border border-solid border-gray-600 border-l-0 border-r-0 border-b-0 py-8">
    {#each fixedItems.concat($NavItems.map((ni) => ({
        ...ni,
        subItems: ni.subItems.map((si) => ({ ...si, link: `/category/${si.link}` })),
      }))) as navItem, index}
      <div>
        <div class="text-lg mb-3">{navItem.title}</div>
        {#each navItem.subItems as subItem}
          <a use:link href="{subItem.link}" class="block text-sm mb-2">
            {subItem.title}
          </a>
        {/each}
      </div>
    {/each}
  </div>
</div>

<div class="lg:hidden px-4 ">
  <div class="border border-solid border-gray-600 border-l-0 border-r-0 border-b-0">
    <div class="flex items-center justify-center py-2">
      <AppLogo />
    </div>

    <div class="grid grid-cols-2">
      {#each fixedItems[0].subItems as navItem, index}
        <a use:link href="{navItem.link}" class="text-sm p-1 mb-2">{navItem.title}</a>
      {/each}
    </div>
  </div>
</div>
