<script lang="ts">
  import { Router, Route } from 'svelte-navigator';
  import AppHeader from '../components/AppHeader.svelte';
  import AppFooter from '../components/AppFooter.svelte';
  import Authors from './Authors.svelte';
  import AuthorPage from './AuthorPage.svelte';
  import About from './info/About.svelte';
  import BecomeAuthor from './info/BecomeAuthor.svelte';
  import Contacts from './info/Contacts.svelte';
  import Agreement from './info/Agreement.svelte';
  import Policy from './info/Policy.svelte';
</script>

<svelte:head>
  <title>Skillday.com</title>
</svelte:head>

<AppHeader />

<Router>
  <Route path="authors" component="{Authors}" />
  <Route path="author/:id" component="{AuthorPage}" />
  <Route path="about" component="{About}" />
  <Route path="become-author" component="{BecomeAuthor}" />
  <Route path="contacts" component="{Contacts}" />
  <Route path="agreement" component="{Agreement}" />
  <Route path="policy" component="{Policy}" />
</Router>

<AppFooter />
