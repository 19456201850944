<script lang="ts">
  import { onMount } from 'svelte';
  import { saveSocial } from '../data/API';
  import { SocialLinks } from '../data/SocialLinks';
  import type { SocialLink } from '../data/SocialLinks';

  const onUpClick = (moveIndex) => {
    if (moveIndex > 0) {
      $SocialLinks = <SocialLink[]>$SocialLinks.map((item, index, arr) => {
        if (index === moveIndex - 1) {
          return arr[moveIndex];
        }
        if (index === moveIndex) {
          return arr[moveIndex - 1];
        }
        return arr[index];
      });
    }
    blur();
  };

  const onDownClick = (moveIndex) => {
    if (moveIndex < $SocialLinks.length - 1) {
      $SocialLinks = <SocialLink[]>$SocialLinks.map((item, index, arr) => {
        if (index === moveIndex + 1) {
          return arr[moveIndex];
        }
        if (index === moveIndex) {
          return arr[moveIndex + 1];
        }
        return arr[index];
      });
    }
    blur();
  };

  const blur = () => {
    saveSocial($SocialLinks);
  };
</script>

<div class="flex-1 ">
  <div class="py-8 text-center text-2xl">Социальные сети</div>

  <div class="px-4">
    {#each $SocialLinks as item, index}
      <div class="mb-8">
        <div class="mb-2 text-xl">{item.name}</div>

        <div class="flex items-center">
          <div class="flex items-center mr-4">
            <i class="fas fa-arrow-up px-2" on:click="{() => onUpClick(index)}"></i>
            <i class="fas fa-arrow-down px-2" on:click="{() => onDownClick(index)}"></i>
          </div>

          <div class="with-border mx-1 flex items-center justify-center mr-4 flex flex-col">
            <i class="{item.icon}"></i>
          </div>

          <div class="flex flex-col mr-4">
            <label>css</label>
            <input readonly bind:value="{item.icon}" class="border px-2 py-1 " />
          </div>

          <div class="flex-1">
            <label>Ссылка</label>
            <input on:blur="{blur}" bind:value="{item.link}" class="border px-2 py-1 w-full" />
          </div>

          <div class='flex items-center ml-6'>
            <input id='check-{index}' bind:checked={item.hidden} type='checkbox' class='mr-2' on:change={blur}>
            <label for='check-{index}'>Скрыть</label>
          </div>
        </div>
      </div>
    {/each}
  </div>
</div>

<style>
  .with-border {
    border: 2px solid #000000;
    outline: 0;
    height: 26px;
    width: 26px;
    border-radius: 6px;
    font-size: 14px;
  }
  .wider {
    width: 400px;
  }
</style>
