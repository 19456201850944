<script lang="ts">
  import AppToolbar from './AppToolbar.svelte';
  import TopBanner from './TopBanner.svelte';
  import TopNav from './TopNav.svelte';
</script>

<div class="lg:container mx-auto px-4 md:px-8">
  <TopBanner />
  <AppToolbar />
  <TopNav />
</div>
