<script lang="ts">
  import { link, navigate } from 'svelte-navigator';
  import { Authors } from '../data/Author';

  const onNewAuthorClick = async () => {
    navigate(`/admin-tools/author/new`);
  };
</script>

<div class="flex-1 ">
  <div class="py-8 text-center text-2xl">Список Колумнистов</div>
  <div class="p-4">
    <button class="px-8 py-2 bg-green-500 hover:bg-green-600 rounded text-white" on:click="{onNewAuthorClick}"
      >Добавить колумниста</button
    >
  </div>
  <div>
    <table class="w-full">
      <thead>
        <tr>
          <th class="bg-blue-200 p-2" style="width: 50px"></th>
          <th class="bg-blue-200 p-2">Имя</th>
        </tr>
      </thead>
      <tbody>
        {#each $Authors as item, index}
          <tr>
            <td class="bg-blue-100 p-2"><input type="checkbox" class="w-4 h-4" /></td>
            <td class="bg-blue-100 p-2"
              ><a class="underline" use:link href="{`/admin-tools/author/${item.id}`}"
                >{item.name && item.name.length ? item.name : `-=без имени=-`}</a
              ></td
            >
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
</div>
