<script lang="ts">
  import { NavItems } from '../data/TopNav';
  import { tick } from 'svelte';
  import { saveTopNavData } from '../data/API';

  let conf = [{ open: false }];

  $: conf = $NavItems.map((item, index) => ({ open: conf[index] && conf[index].open }));

  const onOpenClick = (item, index) => {
    conf[index].open = !conf[index].open;
  };

  const newNavItem = () => {
    let newItems = [...$NavItems, { title: 'введите название', subItems: [], link: '' }];
    $NavItems = newItems;

    onEditItemClick(newItems.length - 1, -1, 'parentitemtitle');
  };

  const newNavSubItem = (index) => {
    let newItems = [...$NavItems];
    newItems[index].subItems.push({ title: 'введите название', link: '#' });
    $NavItems = newItems;

    onEditItemClick(index, newItems[index].subItems.length - 1, 'subitemtitle');
  };

  let editingItemType = '';
  let editingParentItem = -1;
  let editingSubItem = -1;

  const onEditItemClick = async (index1, index2, type) => {
    editingParentItem = index1;
    editingSubItem = index2;
    editingItemType = type;
    await tick();
    const inputEl: HTMLInputElement = document.getElementById('item-input') as HTMLInputElement;
    inputEl.focus();
    inputEl.selectionStart = 0;
    inputEl.selectionEnd = inputEl.value.length;
  };

  const onInputKeyDown = (e) => {
    if (e.code === 'Escape') {
      cancel();
    }
    if (e.code === 'Enter') {
      accept();
    }
  };

  const accept = () => {
    const inputEl: HTMLInputElement = document.getElementById('item-input') as HTMLInputElement;
    let newItems = [...$NavItems];
    if (editingItemType === 'subitemtitle') {
      newItems[editingParentItem].subItems[editingSubItem].title = inputEl.value;
    }
    if (editingItemType === 'subitemlink') {
      newItems[editingParentItem].subItems[editingSubItem].link = inputEl.value;
    }
    if (editingItemType === 'parentitemtitle') {
      newItems[editingParentItem].title = inputEl.value;
    }
    if(editingItemType === 'parentitemlink') {
      newItems[editingParentItem].link = inputEl.value;
    }

    editingParentItem = -1;
    editingSubItem = -1;
    editingItemType = '';

    saveTopNavData($NavItems);
  };

  const cancel = () => {
    editingParentItem = -1;
    editingSubItem = -1;
    editingItemType = '';
  };

  const onSubItemDeleteClick = (index1, index2) => {
    let newItems = [...$NavItems];
    newItems[index1].subItems.splice(index2, 1);

    saveTopNavData(newItems);
  };

  const onItemDeleteClick = (index) => {
    let newItems = [...$NavItems];
    newItems.splice(index, 1);

    saveTopNavData(newItems);
  };
</script>

<div class="flex-1 ">
  <div class="py-8 text-center text-2xl">Настройка навигации</div>
  <div>
    <table class="w-full">
      <thead>
        <tr>
          <th class="bg-blue-200" style="width: 20px;"></th>
          <th class="bg-blue-200 p-2">Название</th>
          <th class="bg-blue-200 p-2" style="width: 70px"></th>
        </tr>
      </thead>
      <tbody>
        {#each $NavItems as item, index}
          <tr>
            <td class="bg-blue-100 p-2" style="vertical-align: top">
              <button
                on:click="{() => onOpenClick(item, index)}"
                class="px-1 py-2 text-lg transform rotate-180 transition-all duration-300"
                class:rotate-180="{conf[index].open}"
              >
                <i class="fas fa-chevron-down"></i>
              </button>
            </td>

            <td class="p-2 bg-blue-100">
              {#if editingParentItem === index && editingSubItem === -1 && editingItemType === 'parentitemtitle'}
                <div class="">
                  <input
                    on:keydown="{onInputKeyDown}"
                    on:blur="{accept}"
                    id="item-input"
                    value="{item.title}"
                    class="py-2 border border-l-0 border-r-0 border-t-0 border-solid border-black bg-transparent w-full"
                  />
                </div>
              {:else}
                <div
                  on:click="{() => onEditItemClick(index, -1, 'parentitemtitle')}"
                  class="py-2 cursor-text border border-l-0 border-r-0 border-t-0 border-solid border-transparent hover:border-black"
                >
                  {item.title}
                </div>
              {/if}

              {#if editingParentItem === index && editingSubItem === -1 && editingItemType === 'parentitemlink'}
                <div class="">
                  <input
                    on:keydown="{onInputKeyDown}"
                    on:blur="{accept}"
                    id="item-input"
                    value="{item.link}"
                    class="py-2 border border-l-0 border-r-0 border-t-0 border-solid border-black bg-transparent w-full"
                  />
                </div>
              {:else}
                <div
                  on:click="{() => onEditItemClick(index, -1, 'parentitemlink')}"
                  class="py-2 cursor-text border border-l-0 border-r-0 border-t-0 border-solid border-transparent hover:border-black"
                >
                  {item.link}
                </div>
              {/if}

              {#if conf[index].open}
                <div class="bg-indigo-100 border border-white border-solid">
                  <table class="w-full">
                    <thead>
                      <tr>
                        <th class="p-2">Название</th>
                        <th class="p-2">Ссылка</th>
                        <th style="width: 70px"></th>
                      </tr>
                    </thead>

                    <tbody>
                      {#each item.subItems as subItem, subItemIndex}
                        <tr>
                          <td class="px-2">
                            {#if editingParentItem === index && editingSubItem === subItemIndex && editingItemType === 'subitemtitle'}
                              <div class="">
                                <input
                                  on:keydown="{onInputKeyDown}"
                                  on:blur="{accept}"
                                  id="item-input"
                                  value="{subItem.title}"
                                  class="py-2 border border-l-0 border-r-0 border-t-0 border-solid border-black bg-transparent w-full"
                                />
                              </div>
                            {:else}
                              <div
                                on:click="{() => onEditItemClick(index, subItemIndex, 'subitemtitle')}"
                                class="py-2 cursor-text border border-l-0 border-r-0 border-t-0 border-solid border-transparent hover:border-black"
                              >
                                {subItem.title}
                              </div>
                            {/if}
                          </td>
                          <td class="p-2">
                            {#if editingParentItem === index && editingSubItem === subItemIndex && editingItemType === 'subitemlink'}
                              <div class="">
                                <input
                                  on:keydown="{onInputKeyDown}"
                                  on:blur="{accept}"
                                  id="item-input"
                                  value="{subItem.link}"
                                  class="py-2 border border-l-0 border-r-0 border-t-0 border-solid border-black bg-transparent w-full"
                                />
                              </div>
                            {:else}
                              <div
                                on:click="{() => onEditItemClick(index, subItemIndex, 'subitemlink')}"
                                class="py-2 cursor-text border border-l-0 border-r-0 border-t-0 border-solid border-transparent hover:border-black"
                              >
                                {subItem.link}
                              </div>
                            {/if}
                          </td>
                          <td class="p-2 text-right">
                            <button
                              on:click="{() => onSubItemDeleteClick(index, subItemIndex)}"
                              class="px-3 py-2 ml-2 text-red-600 text-sm"
                              title="удалить"
                            >
                              <i class="fas fa-ban"></i>
                            </button>
                          </td>
                        </tr>
                      {/each}
                      <tr>
                        <td colspan="3" class="p-2">
                          <div class="text-center">
                            <button
                              class="px-8 py-1 bg-green-500 hover:bg-green-600 rounded text-white"
                              on:click="{() => newNavSubItem(index)}"
                              ><i class="fas fa-plus-circle mr-2"></i><span>добавить</span></button
                            >
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              {/if}
            </td>

            <td class="bg-blue-100 p-2 text-right" style="vertical-align: top">
              <button
                on:click="{() => onItemDeleteClick(index)}"
                class="px-3 py-2 ml-2 text-red-600 text-sm"
                title="удалить"
              >
                <i class="fas fa-ban"></i>
              </button>
            </td>
          </tr>
        {/each}
        <tr>
          <td colspan="3" class="bg-blue-100 p-2">
            <div class="text-center">
              <button class="px-8 py-1 bg-green-500 hover:bg-green-600 rounded text-white" on:click="{newNavItem}"
                ><i class="fas fa-plus-circle mr-2"></i><span>добавить</span></button
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<style>
</style>
