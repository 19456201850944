<script lang="ts">
  import { Router, Route, navigate } from 'svelte-navigator';
  import AdminMenu from '../components/AdminMenu.svelte';
  import AdminArticles from './AdminArticles.svelte';
  import AdminNavigation from './AdminNavigation.svelte';
  import AdminCategories from './AdminCategories.svelte';
  import ArticleMeta from './ArticleMeta.svelte';
  import ArticleEditor from './ArticleEditor.svelte';
  import AdminAuthors from './AdminAuthors.svelte';
  import AuthorEditor from './AuthorEditor.svelte';
  import AdminAdvertisements from './AdminAdvertisements.svelte';
  import AdminIGPosts from './AdminIGPosts.svelte';
  import AdminSocial from './AdminSocial.svelte';
  import AdminLogin from './AdminLogin.svelte';
  import { checkToken } from '../data/API';
  import { onMount } from 'svelte';
  import { authorized } from '../data/User';
  import AdminComments from './AdminComments.svelte';

  onMount(async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      $authorized = false;
      navigate('/admin-tools/login');
    } else {
      const resp = await checkToken(token);

      $authorized = resp.valid === true;

      if (!resp.valid) {
        navigate('/admin-tools/login');
      }
    }
  });
</script>

<svelte:head>
  <title>Административная страница</title>
</svelte:head>

<div class="w-full h-screen flex items-stretch">
  {#if $authorized}
    <AdminMenu />
  {/if}
  <Router>
    <Route path="articles" component="{AdminArticles}" />
    <Route path="navigation" component="{AdminNavigation}" />
    <Route path="social" component="{AdminSocial}" />
    <Route path="categories" component="{AdminCategories}" />
    <Route path="article-meta/:id" component="{ArticleMeta}" />
    <Route path="article-editor/:id" component="{ArticleEditor}" />
    <Route path="authors" component="{AdminAuthors}" />
    <Route path="author/:id" component="{AuthorEditor}" />
    <Route path="advertisements" component="{AdminAdvertisements}" />
    <Route path="ig-posts" component="{AdminIGPosts}" />
    <Route path="login" component="{AdminLogin}" />
    <Route path="comments" component="{AdminComments}" />
    <Route path="/">
      <div class="p-4">
        <div class="text-xl mb-4">Админ панел</div>
        <div class="text-sm">Выберите необходимый пункт меню</div>
      </div>
    </Route>

    <Route>Страница не найдена</Route>
  </Router>
</div>
