<script lang="ts">
  import { link, navigate } from 'svelte-navigator';
  import type { PostItem } from '../data/Post';
  import { onDestroy, onMount } from 'svelte';
  import { io } from 'socket.io-client';
  import config from '../config.json';
  import { getPosts } from '../data/API';

  import { Posts } from '../data/Post';

  const socket = io(config.backend);

  onMount(() => {
    socket.on('refresh posts', async () => {
      $Posts = await getPosts({
        isHidden: true,
      });
    });
  });

  onDestroy(() => {
    socket.off('refresh posts');
  });

  const newArticle = async () => {
    navigate(`/admin-tools/article-meta/new`);
  };
</script>

<div class="flex-1 ">
  <div class="py-8 text-center text-2xl">Список статей</div>
  <div class="p-4">
    <button class="px-8 py-2 bg-green-500 hover:bg-green-600 rounded text-white" on:click="{newArticle}"
      >Добавить статью</button
    >
  </div>
  <div class="main">
    <table class="w-full">
      <thead>
        <tr>
          <th class="bg-blue-200 p-2"></th>
          <th class="bg-blue-200 p-2">Название</th>
          <th class="bg-blue-200 p-2">Псевдоним</th>
          <th class="bg-blue-200 p-2">Категория</th>
          <th class="bg-blue-200 p-2">Автор</th>
          <th class="bg-blue-200 p-2">Дата</th>
          <th class="bg-blue-200 p-2">Скрыто</th>
        </tr>
      </thead>
      <tbody>
        {#each $Posts as item, index}
          <tr>
            <td class="bg-blue-100 p-2"></td>
            <td class="bg-blue-100 p-2"
              ><a class="underline" use:link href="{`/admin-tools/article-meta/${item.id}`}"
                >{item.title && item.title.length ? item.title : `-=нет заголовка=-`}</a
              ></td
            >
            <td
              class="bg-blue-100 p-2 whitespace-no-wrap"
              style="max-width: 200px; overflow: hidden; text-overflow: ellipsis">{item.name}</td
            >
            <td class="bg-blue-100 p-2 whitespace-no-wrap">{item.category}</td>
            <td class="bg-blue-100 p-2 whitespace-no-wrap">{item.authorName}</td>
            <td class="bg-blue-100 p-2 whitespace-no-wrap">{item.datePublished}</td>
            <td class="bg-blue-100 p-2 whitespace-no-wrap text-lg">
              {#if item.isHidden}
                <i class="far fa-check-square"></i>
              {:else}
                <i class="far fa-square"></i>
              {/if}
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
</div>

<style>
  .main {
    height: calc(100vh - 172px);
    overflow: auto;
  }
</style>
