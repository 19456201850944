<script lang="ts">
  import { NavItems } from '../data/TopNav';
  import { link } from 'svelte-navigator';

  let activeNavItemIndex: number = -1;
  let handler1, handler2;

  const onNavItemMouseOver = (i: number) => {
    clearTimeout(handler1);
    clearTimeout(handler2);
    handler2 = setTimeout(() => {
      activeNavItemIndex = i;
    }, 300);
  };

  const onNavItemMouseOut = (e) => {
    clearTimeout(handler2);
    handler1 = setTimeout(() => {
      activeNavItemIndex = -1;
    }, 300);
  };
</script>

<div
  class="hidden lg:flex lg:container mx-auto mb-16 justify-center border-gray-700 border border-l-0 border-r-0 border-b-0 relative"
  on:mouseleave="{(e) => onNavItemMouseOut(e)}"
>
  {#each $NavItems as navItem, index}
    <div
      class="relative text-lg py-6 px-8 nav-item cursor-pointer"
      class:active="{activeNavItemIndex === index}"
      on:mouseenter="{() => onNavItemMouseOver(index)}"
    >
      <a href="/nav/{navItem.link}" use:link>{navItem.title}</a>
    </div>

    <div
      class="nav-sub-item bg-yellow-800 text-gray-100 absolute flex justify-center w-full"
      class:active="{activeNavItemIndex === index}"
    >
      {#each navItem.subItems as subItem}
        <a href="/category/{subItem.link}" use:link class="block text-lg py-4 px-8 cursor-pointer">
          {subItem.title}
        </a>
      {/each}
    </div>
  {/each}
</div>

<style>
  .nav-item::before {
    content: '';
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    background: #c4b597;
    pointer-events: none;
    transition: height 200ms ease-in-out;
  }

  .nav-item.active::before {
    height: 8px;
  }

  .nav-item::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: calc(50% - 8px);
    pointer-events: none;
    border: #c4b597 solid 8px;
    border-left-color: transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    transition-delay: 200ms;
    transition: all 200ms ease-in-out;
    opacity: 0;
  }

  .nav-item.active::after {
    opacity: 1;
  }

  .nav-sub-item {
    transition-delay: 200ms;
    transition: all 200ms ease-in-out;
    opacity: 0;
    z-index: -1;
    top: 100%;
  }

  .nav-sub-item.active {
    opacity: 1;
    z-index: 1;
  }
</style>
