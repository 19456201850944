<script lang="ts">
  import type { PostItem } from '../data/Post';
  import { Authors } from '../data/Author';
  import { savePost, deletePost, getPosts } from '../data/API';
  import { link, navigate } from 'svelte-navigator';
  import Datepicker from 'svelte-calendar';
  import SuggestBox from 'svelte-suggestbox';
  import cyrillicToTranslit from 'cyrillic-to-translit-js';
  import { formatDate } from 'timeUtils';
  import { onDestroy, onMount } from 'svelte';
  import { io } from 'socket.io-client';
  import config from '../config.json';
  import { NavItems } from '../data/TopNav';

  export let id: string = '';

  let data: PostItem = {
    authorId: '',
    authorName: '',
    title: '',
    name: '',
    category: '',
    tags: [],
    datePublished: '',
    id: '',
    picture: '',
    shortText: '',
    fullText: '',
    paragraphs: [{ content: '' }],
    isHidden: false,
  };

  let tagsRaw = '';
  let selectedAuthor = [];
  let selectedCategory = [];

  const socket = io(config.backend);

  onMount(() => {
    socket.on('refresh posts', () => {
      loadData(id, $Authors, $NavItems);
    });
  });

  onDestroy(() => {
    socket.off('refresh posts');
  });

  const loadData = async (_id, _authors, _navItems) => {
    if (_id === 'new') {
      theDate = new Date();
    } else {
      const resp = await getPosts({
        id: _id,
        isHidden: true,
      });
      if (resp.length !== 1) {
        console.error('postId: ', _id, ' not found');
        return;
      }
      data = { ...resp[0] };
      tagsRaw = data.tags.join(' ');
      if (_authors?.length) {
        selectedAuthor = _authors.filter((a) => a.id === data.authorId);
      }
      if (_navItems?.length) {
        selectedCategory = allCategories.filter((cat) => cat.title === data.category);
      }
      theDate = new Date(data.datePublished);
    }
  };

  $: loadData(id, $Authors, $NavItems);

  let errorName = false;

  const blur = async () => {
    if (!data.name?.length) return;

    const resp = await getPosts({
      name: data.name,
      isHidden: true,
    });
    if (resp.filter((p) => p.id !== data.id).length > 0) {
      errorName = true;
      return;
    } else {
      errorName = false;
    }

    data.tags = tagsRaw.split(' ').filter((t) => t.length > 0);
    try {
      const newId = await savePost({
        id,
        title: data.title,
        name: data.name,
        authorId: data.authorId,
        authorName: data.authorName,
        category: data.category,
        tags: data.tags,
        datePublished: data.datePublished,
        shortText: data.shortText,
        picture: data.picture,
        fullText: data.fullText,
        paragraphs: data.paragraphs,
        isHidden: data.isHidden,
      });
      if (id === 'new') {
        navigate(`/admin-tools/article-meta/${newId}`);
      }
    } catch (e) {
      if (e.response.status === 401) {
        localStorage.removeItem('token');
      } else {
        navigate(`/error`);
      }
    }
  };

  const deleteArticle = async () => {
    await deletePost(id);
  };

  const selectAuthor = (item) => {
    const author = item.detail;
    data.authorId = author.id;
    data.authorName = author.name;
    blur();
  };

  const translit = () => {
    if (data?.name?.length === 0) {
      data.name = cyrillicToTranslit().transform(data.title, '-').toLowerCase();
      data.name = data.name
        .split('')
        .filter((s) => /[a-zA-Z0-9\-]/.test(s))
        .join('');
    }
  };

  let allCategories: { name: string; title: string }[] = [];

  const getAllCategories = (_navItems) => {
    allCategories = [];
    _navItems.forEach((ni) => {
      ni.subItems.forEach((si) => {
        allCategories.push({ name: `${ni.title}/${si.title}`, title: si.title });
      });
    });
  };

  const selectCategory = (item) => {
    data.category = item.detail.title;
    blur();
  };

  let theDate;

  $: data && theDate && (data.datePublished = formatDate(theDate, '#{Y}-#{m}-#{d}'));
  $: getAllCategories($NavItems);
</script>

<div class="flex-1 lg:container mx-auto px-4 h-screen overflow-auto text-gray-900">
  <div class="py-8 text-center text-2xl">основные данные</div>

  <div
    class="flex items-center mb-8"
    on:click="{() => {
      data.isHidden = !data.isHidden;
      blur();
    }}"
  >
    {#if data.isHidden}
      <i class="far fa-check-square text-2xl mr-5"></i>
    {:else}
      <i class="far fa-square text-2xl mr-5"></i>
    {/if}
    <label for="title">Скрыть статью</label>
  </div>

  <div class="flex flex-col mb-8">
    <label for="title">Заголовок</label>
    <input
      id="title"
      class="p-2 border-gray-900 border"
      bind:value="{data.title}"
      on:blur="{() => {
        translit();
        blur();
      }}"
    />
  </div>

  <div class="flex flex-col mb-8">
    <label for="title"
      >Псевдоним {#if errorName}<span class="text-red-500">(ошибка: данный псевдоним используется в другой статье)</span
        >{/if}</label
    >
    <input
      id="name"
      class="p-2 border-gray-900 border"
      class:border-red-500="{errorName}"
      bind:value="{data.name}"
      on:blur="{blur}"
    />
  </div>

  <div class="flex flex-col mb-8 relative">
    <label>Категория</label>

    <SuggestBox
      placeholder="{''}"
      items="{allCategories}"
      multiSelect="{false}"
      selectedItems="{selectedCategory}"
      on:onItemSelected="{selectCategory}"
    />
  </div>

  <div class="flex flex-col mb-8">
    <label for="tags">Тэги</label>
    <input id="tags" class="p-2 border-gray-900 border" bind:value="{tagsRaw}" on:blur="{blur}" />
  </div>

  <div class="flex flex-col mb-8 relative">
    <label>Автор</label>

    <SuggestBox
      placeholder="{''}"
      items="{$Authors}"
      multiSelect="{false}"
      selectedItems="{selectedAuthor}"
      on:onItemSelected="{selectAuthor}"
    />
  </div>

  <div class="flex flex-col mb-8">
    <label>Дата</label>
    <div class="p-2 border-gray-900 border">
      {#if theDate}
        <Datepicker style="border: none; box-shadow: none; width: 100%; text-align: left;" bind:selected="{theDate}">
          <button>{formatDate(theDate, '#{Y}-#{m}-#{d}')}</button>
        </Datepicker>
      {/if}
    </div>

    <!--    <input id="date-published" class="p-2 border-gray-900 border" bind:value="{data.datePublished}" on:blur="{blur}" />-->
  </div>

  <div class="flex flex-col mb-8">
    <label for="short-text">Короткое описание</label>
    <input id="short-text" class="p-2 border-gray-900 border" bind:value="{data.shortText}" on:blur="{blur}" />
  </div>

  <div class="flex justify-center mb-8">
    <a
      on:click="{(e) => (!data.name || !data.name.length ? e.preventDefault() : '')}"
      use:link
      href="{`/admin-tools/article-editor/${id}`}"
      class="{'px-8 py-2 rounded text-white ' + (data.name && data.name.length ? ' hover:bg-green-600 ' : '')}"
      class:bg-green-500="{data.name && data.name.length}"
      class:bg-gray-500="{!data.name || !data.name.length}"
    >
      Редактировать текст
    </a>
  </div>
  {#if data.name && data.name.length}
    <div class="flex justify-center mb-8">
      <button
        on:click="{deleteArticle}"
        class="bg-red-500 flex items-center justify-center px-8 py-2 rounded text-white hover:bg-red-600"
      >
        <i class="far fa-trash-alt mr-2 text-xl"></i>
        <span>Удалить статью</span>
      </button>
    </div>
  {/if}
</div>

<style global>
  #author-field .suggest-box .input {
    border-color: #111111;
  }
</style>
