<script lang="ts">
  import { topBanner } from '../data/Banners';
</script>

{#if $topBanner && $topBanner.imgSrc && $topBanner.imgSrc.length}
  <div class="hidden md:flex lg:container mx-auto w-full ">
    <a href="{$topBanner.url}" target="_blank" class="w-full text-white banner overflow-hidden"
      ><img src="{$topBanner.imgSrc}" /></a
    >
  </div>
{/if}

{#if $topBanner && $topBanner.mobileImgSrc && $topBanner.mobileImgSrc.length}
  <div class="flex md:hidden ">
    <a href="{$topBanner.url}" target="_blank" class="w-full text-white banner overflow-hidden"
      ><img src="{$topBanner.mobileImgSrc}" /></a
    >
  </div>
{/if}

<style>
  .banner {
    background: #494949;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 27px;
    font-weight: 100;
    font-family: 'Open Sans', sans-serif;
  }
</style>
