<script lang="ts">
  import { fade, fly } from 'svelte/transition';
  import { createEventDispatcher } from 'svelte';
  import { link } from 'svelte-navigator';

  import { NavItems } from '../data/TopNav';

  const dispatch = createEventDispatcher();

  const openItems = [];

  const fixedItems = [
    {
      title: 'Главная',
      link: '/',
    },
    {
      title: 'Об издании',
      link: '/info/about',
    },
    {
      title: 'Стать автором',
      link: '/info/become-author',
    },
    {
      title: 'Колумнисты',
      link: '/info/authors',
    },
    {
      title: 'Редакция',
      link: '/info/contacts',
    },
    {
      title: 'Соглашение',
      link: '/info/agreement',
    },
    {
      title: 'Политика',
      link: '/info/policy',
    },
  ];
</script>

<div
  on:touchstart="{() => dispatch('close')}"
  class="fixed z-20 bg-black opacity-25 inset-0 w-screen h-screen"
  transition:fade
></div>

<div class="fixed z-20 bg-white inset-0 h-screen w-3/4 overflow-auto" transition:fly="{{ x: -200 }}">
  <div class="pl-2 py-8">
    {#each $NavItems as navItem, index}
      <div
        class="text-lg nav-item py-2 px-4 font-bold tracking-widest"
        on:touchstart="{() => (openItems[index] = !openItems[index])}"
      >
        {navItem.title}
      </div>

      {#if openItems[index]}
        {#each navItem.subItems as subItem}
          <a
            href="/category/{subItem.link}"
            use:link
            on:click="{() => dispatch('close')}"
            class="block nav-sub-item text-lg py-2 px-4 pl-8"
          >
            {subItem.title}
          </a>
        {/each}
      {/if}
    {/each}

    <div
      class="text-lg nav-item py-2 px-4 font-bold tracking-widest uppercase"
      on:touchstart="{() => (openItems[-1] = !openItems[-1])}"
    >
      Разделы
    </div>

    {#if openItems[-1]}
      {#each fixedItems as subItem}
        <a
          href="{subItem.link}"
          use:link
          on:click="{() => dispatch('close')}"
          class="block nav-sub-item text-lg py-2 px-4 pl-8"
        >
          {subItem.title}
        </a>
      {/each}
    {/if}
  </div>
</div>

<style>
</style>
