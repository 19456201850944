<script lang="ts">
  import { onMount } from 'svelte';

  let data = [
    {
      name: 'София Цай',
      title: 'Digital Директор',
      email: 'sofia@skillady.com',
    },
    {
      name: 'Дмитрий Сычев',
      title: 'Технический директор',
      email: 'dsychev@skillady.com',
    },
    {
      name: 'Наталья Феоктистова',
      title: 'Реклама и сотрудничество',
      email: 'adv@skillady.com',
    },
    {
      name: 'Екатерина Полумискова',
      title: 'SMM-менеджер',
      email: 'kate@skillady.com',
    },
  ];

  onMount(async () => {
    if (location.host !== 'jsdom.ssr') {
      document.documentElement.scrollTop = 0;
    }
    setTimeout(() => {
      dispatchEvent(new CustomEvent('app-loaded'));
    }, 100);
  });
</script>

<div class="container mx-auto px-8 pb-32">
  <h1 class="text-5xl md:text-6xl text-center pb-12">Контакты</h1>

  <div class="grid grid-cols-2 gap-12">
    <div class="text-xl">Связаться с редакцией: info@skillady.com</div>
  </div>
</div>
