<script lang="ts">
  import { IGPosts } from '../data/IGProfile';
  import { SocialLinks } from '../data/SocialLinks';
</script>

<div class="block lg:container mx-auto px-4 lg:px-8 mb-8" class:hidden="{location.host === 'jsdom.ssr'}">
  <div class="pt-8 pb-8">
    <div class="text-xl text-center mb-2">
      <a href="https://www.instagram.com/explore/tags/skillady/" target="_blank">#skillady</a>
    </div>
  </div>

  <div class="hidden lg:flex mb-12 flex-row-reverse">
    {#each $IGPosts as post, index}
      {#if index >= $IGPosts.length - 5}
        <a href="{post.link}" target="_blank" class="flex-1 mx-4" style="height: 292px; overflow: hidden">
          <img class="max-w-full object-cover" src="{post.picture}" />
        </a>
      {/if}
    {/each}
  </div>

  <div class='flex lg:hidden mb-12 flex-row-reverse'>
    {#each $IGPosts as post, index}
      {#if index >= $IGPosts.length - 1}
        <a href="{post.link}" target="_blank" class="flex-1 mx-4" style="height: 292px; overflow: hidden">
          <img class="max-w-full object-cover" src="{post.picture}" />
        </a>
      {/if}
    {/each}
  </div>

  <div class="text-center flex items-center justify-center mb-12">
    <a href="https://www.instagram.com/skillady_/" target="_blank" class="block px-8 py-2 bg-black text-white">
      Подписаться на Instagram
    </a>
  </div>

  <div class="flex items-center text-2xl justify-center mb-16">
    {#each $SocialLinks as item}
      {#if !item.hidden}
        <a class="with-border mx-1 flex items-center justify-center" target="_blank" href="{item.link}">
          <i class="{item.icon}"></i>
        </a>
      {/if}
    {/each}
  </div>
</div>

<style>
  .with-border {
    border: 2px solid #000000;
    outline: 0;
    height: 26px;
    width: 26px;
    border-radius: 6px;
    font-size: 14px;
  }
</style>
