<script lang="ts">
  import { auth } from '../data/API';
  import { navigate } from 'svelte-navigator';
  import { authorized } from '../data/User';

  let username;
  let password;

  const onLoginClick = async () => {
    try {
      const token = await auth(username, password);
      localStorage.setItem('token', token);
      $authorized = true;
      navigate('/admin-tools');
    } catch (e) {
      alert('ошибка');
    }
  };
</script>

<main class="fixed inset-0 m-auto shadow-md border rounded">
  <div class="h-full flex">
    <div class="w-1/2 bg-gray-100 rounded-lg flex items-center justify-center">
      <img src="/img/slblack200.png" />
    </div>
    <div class="w-1/2 flex flex-col px-8 justify-center relative">
      <div class="flex flex-col mb-4">
        <label class="mb-2 tracking-wider text-gray-500 ">Пользователь:</label>
        <input class="border px-2 py-1 rounded" bind:value="{username}" />
      </div>

      <div class="flex flex-col mb-8">
        <label class="mb-2 tracking-wider text-gray-500 ">Пароль:</label>
        <input class="border px-2 py-1 rounded" type="password" bind:value="{password}" />
      </div>

      <div class="flex items-center justify-center">
        <button on:click="{onLoginClick}" class=" bg-green-500 text-white hover:bg-green-400 rounded px-10 py-2">
          Войти
        </button>
      </div>
    </div>
  </div>
</main>

<style>
  main {
    width: 600px;
    height: 300px;
  }

  img {
    max-width: 100px;
  }

  input {
    width: 100%;
  }
</style>
