<script lang="ts">
  import { onMount } from 'svelte';

  onMount(async () => {
    if (location.host !== 'jsdom.ssr') {
      document.documentElement.scrollTop = 0;
    }
    setTimeout(() => {
      dispatchEvent(new CustomEvent('app-loaded'));
    }, 100);
  });
</script>

<div class="container mx-auto px-8 pb-32">
  <h1 class="text-2xl mb-8 font-bold">Как стать автором онлайн-издания SKILLADY</h1>

  <div class="text-lg mb-8">
    SKILLADY - это уникальный сайт обо всём, что может быть интересно современной женщине. Если у вас есть опыт написания
    статей, ваши материалы полезны и актуальны — вы хотите размещаться на нашем сайте, мы будем рады помочь вам в этом.
  </div>

  <div class="text-lg mb-8">
    Нам интересны материалы про личные финансы, бизнес, карьеру, технологии, фриланс, маркетинг, красоту и здоровье,
    психологию, культуру, путешествия.
  </div>

  <div class="text-lg font-bold mb-8">Требования к статьям следующие:</div>

  <div class="pl-12 text-lg mb-8">
    <ol>
      <li>
        Если статья ранее уже была опубликована в другом журнале такой материал нам не подходит. Копипаст не принимается.
        Пишите простым и понятным языком. Сложные и длинные предложения разбивайте на несколько. Одно предложение - одна
        мысль.
      </li>
      <li>Пишите от 3-го лица без местоимений: "я", "мы", "вы, вам, вас".</li>
      <li>
        Должно быть краткое описание статьи: максимальная длина 255 символов с пробелами. Аннотация (введение) в статье
        обязательна. Объем статьи - 5000 знаков и больше, без пробелов.
      </li>
      <li>
        Проверяйте статью на уникальность текста и тошноту. Спам в тексте должен быть не более 50% (<a
          href="https://text.ru/"
          target="_blank">онлайн-сервиса проверки текста на спамность</a
        >). Уникальность текста не менее 90%, воспользуйтесь программой
        <a href="https://www.etxt.biz/antiplagiat/" target="_blank">проверки текста.</a>
        Проверяйте текст на <a href="http://contentmonster.ru/empty/list" target="_blank">стоп-фразы,</a> исключите их.
        Завершающий шаг проверки на чистоту и читаемость (<a href="https://glvrd.ru/" target="_blank">Главред,</a> ваш текст
        должен получить не менее 7 баллов).
      </li>
      <li>
        Оформление. Орфографию и пунктуацию в статьях никто не отменял - старайтесь избегать банальных и глупых ошибок.
        Информация в статье должна излагаться последовательно и структурировано. Используйте заголовки (не менее трех),
        цитаты и прочие инструменты для выделения в тексте. Изображения внутри статьи обязательны, прикрепляйте отдельным
        файлом, подписав последовательность.<br />
        Фотографии должны быть хорошего качества. В статье должно быть заключение, подведение итогов обязательно. Статью отправляйте
        в формате MS Word, PDF не принимаем.
      </li>
      <li>Чем подробнее вы опишите статью, тем лучше.</li>
      <li>
        Знаки. Старайтесь писать так, чтобы полностью раскрыть тему, чтобы вам было интересно читать и вы хотели бы
        поделиться статьей с друзьями или коллегами.
      </li>
      <li>Статья не принята. Возникают и такое. Возможно материал не подошел и он не поддается редактированию.</li>
    </ol>
  </div>

  <div class="text-lg mb-8">
    Готовый материал и презентабельный портрет в хорошем качестве отправляйте на почту с пометкой "Стать автором": <a
      href="mailto:info@skillady.com">info@skillady.com</a
    >
  </div>

  <div class="text-lg mb-8">
    Возникли вопросы, пишите на почту: <a href="mailto:info@skillady.com">info@skillady.com</a>
  </div>

  <div class="flex items-center justify-center">
    <img
      alt=""
      src="https://firebasestorage.googleapis.com/v0/b/triple-mountain-280420.appspot.com/o/skillady%2Fbecome_author.jpg?alt=media&token=a1902822-87a0-4639-a0bd-af51567e773f"
    />
  </div>
</div>

<style>
  a {
    @apply text-blue-700 underline;
  }
  li {
    list-style: auto;
  }
</style>
