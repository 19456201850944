<script lang="ts">
  import { link, navigate } from 'svelte-navigator';
  import { authorized } from '../data/User';

  const onLoginClick = () => {};

  const onLogoutClick = () => {
    $authorized = false;
    localStorage.removeItem('token');
    navigate('/admin-tools/login');
  };

  let blocks: any[] = [
    {
      name: 'Статьи',
      link: 'articles',
    },
    {
      name: 'Навигация',
      link: 'navigation',
    },
    {
      name: 'Соц. сети',
      link: 'social',
    },
    {
      name: 'Колумнисты',
      link: 'authors',
    },
    {
      name: 'Рекламные блоки',
      link: 'advertisements',
    },
    {
      name: 'Ссылки Instagram',
      link: 'ig-posts',
    },
    {
      name: 'Комментарии',
      link: 'comments',
    },
  ];
</script>

<div class="flex flex-col h-full">
  <div class="flex justify-between bg-blue-900">
    <button class="flex-1 px-16 py-4 text-white uppercase tracking-widest" on:click="{() => onLoginClick()}">
      Вход
    </button>

    <button class="bg-blue-800 px-4" on:click="{() => onLogoutClick()}">
      <i class="fas fa-sign-out-alt"></i>
    </button>
  </div>

  <div class="bg-blue-700 text-white flex-1">
    {#each blocks as block}
      <a href="{`/admin-tools/${block.link}`}" use:link class="block p-4 cursor-pointer hover:bg-blue-600"
        >{block.name}</a
      >
    {/each}
  </div>
</div>
