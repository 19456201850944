<script lang="ts">
  import { updateSideBanner, updateTopBanner } from '../data/API';
  import { topBanner, sideBanner } from '../data/Banners';
  import axios from 'axios';
  import config from '../config.json';

  const fileSelect = async (e, location) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;

    if (location === 'top-desktop') {
      const file = files[0];
      const formData = new FormData();
      formData.append('image_file', file);
      const resp = await axios.put(`${config.backend}/upload`, formData, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      $topBanner.imgSrc = `${config.cdn}/${resp.data}`;

      await updateTopBanner($topBanner.url, $topBanner.imgSrc, $topBanner.mobileImgSrc);
    }

    if (location === 'top-mobile') {
      const file = files[0];
      const formData = new FormData();
      formData.append('image_file', file);
      const resp = await axios.put(`${config.backend}/upload`, formData, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      $topBanner.mobileImgSrc = `${config.cdn}/${resp.data}`;

      await updateTopBanner($topBanner.url, $topBanner.imgSrc, $topBanner.mobileImgSrc);
    }

    if (location === 'side') {
      const file = files[0];
      const formData = new FormData();
      formData.append('image_file', file);
      const resp = await axios.put(`${config.backend}/upload`, formData, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      $sideBanner.imgSrc = `${config.cdn}/${resp.data}`;

      await updateSideBanner($sideBanner.url, $sideBanner.imgSrc);
    }
  };

  const onBlur = () => {
    updateTopBanner($topBanner.url, $topBanner.imgSrc, $topBanner.mobileImgSrc);
    updateSideBanner($sideBanner.url, $sideBanner.imgSrc);
  };

  const onDeleteTop = (which: string) => {
    if (which === 'desktop') {
      updateTopBanner($topBanner.url, '', $topBanner.mobileImgSrc);
    }
    if (which === 'mobile') {
      updateTopBanner($topBanner.url, $topBanner.imgSrc, '');
    }
  };

  const onDeleteSide = () => {
    updateSideBanner($sideBanner.url, '');
  };
</script>

<div class="flex-1 lg:container mx-auto px-4 h-screen overflow-auto text-gray-900">
  <div class="py-8 text-center text-2xl">Рекламные блоки</div>

  <div class="mb-4 text-xl">Главный баннер</div>
  <div class="mb-4">
    <div>URL:</div>
    <div>
      <input class="px-4 py-2 border w-full" bind:value="{$topBanner.url}" on:blur="{onBlur}" />
    </div>
  </div>

  <div class="mb-4">
    <div>Изображение - настольная версия:</div>
    <div class="relative border mb-2">
      <div class="text-3xl flex items-center justify-center p-4">
        <i class="fas fa-cloud-upload-alt"></i>
      </div>
      {#if $topBanner && $topBanner.imgSrc}
        <img src="{$topBanner.imgSrc}" alt="" />
      {/if}
      <input
        type="file"
        class="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
        on:change="{(e) => fileSelect(e, 'top-desktop')}"
      />
    </div>
    {#if $topBanner && $topBanner.imgSrc}
      <div class="relative border">
        <button class=" flex items-center justify-center p-4" on:click="{() => onDeleteTop('desktop')}">
          <span class="mr-4">удалить</span>
          <i class="fas fa-trash-alt text-3xl"></i>
        </button>
      </div>
    {/if}
  </div>

  <div class="mb-4">
    <div>Изображение - мобильная версия:</div>
    <div class="relative border mb-2">
      <div class="text-3xl flex items-center justify-center p-4">
        <i class="fas fa-cloud-upload-alt"></i>
      </div>
      {#if $topBanner && $topBanner.mobileImgSrc}
        <img src="{$topBanner.mobileImgSrc}" alt="" />
      {/if}
      <input
        type="file"
        class="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
        on:change="{(e) => fileSelect(e, 'top-mobile')}"
      />
    </div>
    {#if $topBanner && $topBanner.mobileImgSrc}
      <div class="relative border">
        <button class=" flex items-center justify-center p-4" on:click="{() => onDeleteTop('mobile')}">
          <span class="mr-4">удалить</span>
          <i class="fas fa-trash-alt text-3xl"></i>
        </button>
      </div>
    {/if}
  </div>

  <div class="mb-4 text-xl">Боковой баннер</div>
  <div class="mb-4">
    <div>URL:</div>
    <div>
      <input class="px-4 py-2 border w-full" bind:value="{$sideBanner.url}" on:blur="{onBlur}" />
    </div>
  </div>

  <div class="mb-4">
    <div>Файл:</div>
    <div class="relative border mb-2">
      <div class="text-3xl flex items-center justify-center p-4">
        <i class="fas fa-cloud-upload-alt"></i>
      </div>
      {#if $sideBanner && $sideBanner.imgSrc}
        <img src="{$sideBanner.imgSrc}" alt="" />
      {/if}
      <input
        type="file"
        class="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
        on:change="{(e) => fileSelect(e, 'side')}"
      />
    </div>
    {#if $sideBanner && $sideBanner.imgSrc}
      <div class="relative border">
        <button class=" flex items-center justify-center p-4" on:click="{onDeleteSide}">
          <span class="mr-4">удалить</span>
          <i class="fas fa-trash-alt text-3xl"></i>
        </button>
      </div>
    {/if}
  </div>
</div>
