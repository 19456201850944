<script lang="ts">
  import { onDestroy, onMount, tick } from 'svelte';
  import EditorParagraph from '../components/EditorParagraph.svelte';
  import type { Paragraph, PostItem } from '../data/Post';
  import { getPosts, savePost } from '../data/API';
  import AppLogo from '../components/AppLogo.svelte';
  import { link } from 'svelte-navigator';
  import { Authors } from '../data/Author';
  import { io } from 'socket.io-client';
  import config from '../config.json';

  export let id: string;

  let data: PostItem = {
    authorId: '',
    authorName: '',
    title: '',
    name: '',
    category: '',
    datePublished: '',
    tags: [],
    id: '',
    picture: '',
    shortText: '',
    fullText: '',
    paragraphs: [
      {
        content: '',
      },
    ],
    isHidden: false,
  };

  const socket = io(config.backend);

  onMount(() => {
    socket.on('refresh posts', () => {
      loadData(id);
    });
  });

  onDestroy(() => {
    socket.off('refresh posts');
  });

  const loadData = async (_id) => {
    const resp = await getPosts({
      id: _id,
      isHidden: true,
    });
    if (resp.length === 0) {
      console.error('postId: ', _id, ' not found');
      return;
    }
    data = { ...resp[0] };
  };

  $: loadData(id);

  const blur = async () => {
    await savePost({
      id,
      title: data.title,
      name: data.name,
      authorId: data.authorId,
      authorName: data.authorName,
      category: data.category,
      tags: data.tags,
      datePublished: data.datePublished,
      shortText: data.shortText,
      picture: data.picture,
      fullText: data.fullText,
      paragraphs: data.paragraphs,
      isHidden: data.isHidden,
    });
  };

  const addParagraph = async (e) => {
    data.paragraphs = [
      ...data.paragraphs.filter((p, i) => i < activeParagraph),
      {
        ...e.detail.data,
        content: e.detail.before,
      },
      { content: e.detail.after },
      ...data.paragraphs.filter((p, i) => i > activeParagraph),
    ];
    activeParagraph++;
    await tick();
    document.getElementById('current-paragraph').focus();
  };

  let activeParagraph: number = -1;

  const openParagraph = async (index) => {
    activeParagraph = index;

    await tick();
    document.getElementById('current-paragraph').focus();
  };

  const removeParagraph = async () => {
    if (data.paragraphs.length === 1) return;
    data.paragraphs = [
      ...data.paragraphs.filter((p, i) => i < activeParagraph),
      ...data.paragraphs.filter((p, i) => i >= activeParagraph + 1),
    ];
    if (activeParagraph > 0) {
      activeParagraph--;
    }
    await tick();
    document.getElementById('current-paragraph').focus();
  };

  const changeType = async (p: Paragraph, type: string) => {
    if (p.type === type) {
      p.type = '';
    } else {
      p.type = type;
    }
    data.paragraphs = [...data.paragraphs];
    await blur();
  };

  const saveImage = async (p: Paragraph, url: string) => {
    p.imageUrl = url;
    p.imagePosition = 'center';
    data.paragraphs = [...data.paragraphs];
    if (activeParagraph === 0) {
      data.picture = url;
    }
    await blur();
  };

  const removeImage = async (p: Paragraph) => {
    delete p.imageUrl;
    delete p.imagePosition;
    data.paragraphs = [...data.paragraphs];
    if (activeParagraph === 0) {
      delete data.picture;
    }
    await blur();
  };
</script>

<div class="flex-1 lg:container mx-auto px-4 h-screen overflow-auto pb-24">
  <div class="flex items-center ">
    <AppLogo />
  </div>

  <div>
    <a use:link href="{`/admin-tools/article-meta/${id}`}"
      ><i class="fas fa-chevron-left"></i>&nbsp;&nbsp;&nbsp;редактировать основные данные</a
    >
  </div>

  <div class="py-8 text-center text-2xl">Редактор текста</div>

  <div class="flex text-sm">
    <div class="">{data.authorName}</div>
    <div class="mx-2">/</div>
    <div>{data.datePublished}</div>
    <div class="mx-2">/</div>
    <div class="text-gray-500">{data.category}</div>
  </div>

  <div class="title  text-5xl leading-tight mb-8">{data.title}</div>

  {#each data.paragraphs as paragraph, index}
    <EditorParagraph
      isActive="{index === activeParagraph}"
      data="{paragraph}"
      bind:content="{paragraph.content}"
      on:blur="{blur}"
      on:add-paragraph="{addParagraph}"
      on:open-paragraph="{() => openParagraph(index)}"
      on:remove-paragraph="{removeParagraph}"
      on:change-type="{(e) => changeType(paragraph, e.detail)}"
      on:image-upload="{(e) => saveImage(paragraph, e.detail)}"
      on:change-image-position="{blur}"
      on:remove-image="{() => removeImage(paragraph)}"
    />
  {/each}
</div>

<style>
  .title {
    font-family: 'Playfair Display', serif;
  }
</style>
