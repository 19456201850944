<script lang="ts">
  import { onMount } from 'svelte';

  onMount(async () => {
    if (location.host !== 'jsdom.ssr') {
      document.documentElement.scrollTop = 0;
    }
    setTimeout(() => {
      dispatchEvent(new CustomEvent('app-loaded'));
    }, 100);
  });
</script>

<div class="container mx-auto px-8 pb-20">
  <h1 class="text-5xl md:text-6xl text-center pb-8">О проекте</h1>

  <div class="text-xl mb-16">
    SKILLADY - онлайн-издание для творческих, амбициозных и решительных женщин. Мы пишем для тех, кто открывает для себя
    новые горизонты в развитии и постоянно работает над собой. Для тех кто не боится продемонстрировать окружающим свои
    слабости и понимает, что никто не добивается успеха в одиночку. Мы публикуем, только ту информацию, которая поможет
    вам стать еще более уверенными, красивыми и успешными, чувствовать себя еще более гармоничными и никогда не
    останавливаться. То, что началось в 2018 году, переросло в гораздо большее, и эта первоначальная искра никогда не
    исчезала - мотивировать и вдохновлять женщин.
  </div>

  <div class="text-center">
    <img
      class="mx-auto"
      alt=""
      src="https://firebasestorage.googleapis.com/v0/b/triple-mountain-280420.appspot.com/o/skillady%2Fabout.jpg?alt=media&token=f1e93d9f-74d9-4942-afca-89a449a90a9c"
    />
  </div>
</div>
