<script lang="ts">
  import { createEventDispatcher, tick } from 'svelte';
  import type { Paragraph } from '../data/Post';
  import axios from 'axios';
  import config from '../config.json';

  export let content: string;
  export let isActive: boolean;
  export let data: Paragraph;

  const dispatch = createEventDispatcher();

  let textEl: HTMLTextAreaElement;

  const keypress = async (e) => {
    if (e.key == 'Enter') {
      if (!e.ctrlKey) {
        content = content.substr(0, textEl.selectionStart) + `<br>` + content.substring(textEl.selectionStart);

        let savedSelectionStart = textEl.selectionStart;
        await tick();
        textEl.selectionStart = savedSelectionStart + 4;
        textEl.selectionEnd = savedSelectionStart + 4;
      } else {
        dispatch('add-paragraph', {
          data,
          before: textEl.value.substr(0, textEl.selectionStart),
          after: textEl.value.substr(textEl.selectionStart),
        });
        e.preventDefault();
      }
    }
    if (e.key == 'Backspace') {
      if (content.length == 0) {
        dispatch('remove-paragraph');
        e.preventDefault();
      }
    }
  };

  let areaHeight: number = 200;

  const onParagraphClick = (e) => {
    let el = e.target;
    while (!el.classList.contains('paragraph-preview-text')) {
      el = el.parentNode;
    }
    areaHeight = el.clientHeight;

    dispatch('open-paragraph');
  };

  const onApplyTagClick = async (tagName, e) => {
    let savedSelectionStart = textEl.selectionStart;
    let savedSelectionEnd = textEl.selectionEnd;

    content =
      content.substr(0, textEl.selectionStart) +
      `<${tagName}>` +
      content.substring(textEl.selectionStart, textEl.selectionEnd) +
      `</${tagName}>` +
      content.substring(textEl.selectionEnd);

    document.getElementById('current-paragraph').focus();
    await tick();
    textEl.selectionStart = savedSelectionStart;
    textEl.selectionEnd = savedSelectionEnd + 5 + tagName.length * 2;
  };

  const fileSelect = async (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;
    const file = files[0];

    const formData = new FormData();
    formData.append('image_file', file);
    const resp = await axios.put(`${config.backend}/upload`, formData, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    });

    dispatch('image-upload', `${config.cdn}/${resp.data}`);
  };

  const changeImagePosition = async (position) => {
    data.imagePosition = position;
    dispatch('change-image-position');
  };

  const removeImage = async () => {
    dispatch('remove-image');
  };

  let showNewLink = false;
  let linkText;
  let linkAddress;

  let onLinkClick = () => {
    if (showNewLink) {
      showNewLink = false;
      return;
    }
    showNewLink = true;
    linkAddress = '';

    let savedSelectionStart = textEl.selectionStart;
    let savedSelectionEnd = textEl.selectionEnd;
    if (savedSelectionEnd - savedSelectionStart > 0) {
      linkText = content.substring(textEl.selectionStart, textEl.selectionEnd);
    } else {
      linkText = '';
    }
  };

  let onLinkAdd = () => {
    let savedSelectionStart = textEl.selectionStart;
    let savedSelectionEnd = textEl.selectionEnd;

    content =
      content.substr(0, textEl.selectionStart) +
      `<a href='${linkAddress}' target='_blank'>${linkText}</a>` +
      content.substring(textEl.selectionEnd);

    linkText = '';
    linkAddress = '';
    showNewLink = false;
  };
</script>

{#if isActive}
  <div class="wysiwyg flex flex-col pt-16 relative border-gray-500 border border-solid mx-24 shadow-md">
    <div
      class="absolute flex top-0 bg-blue-100 w-full p-2 border border-solid border-gray-500 border-t-0 border-l-0 border-r-0"
    >
      <div class="flex bg-blue-200 shadow-sm rounded mr-2">
        <button
          on:click="{(e) => onApplyTagClick('strong', e)}"
          class="w-10 py-2 cursor-pointer text-gray-900 hover:text-green-700"
        >
          <b>Ж</b>
        </button>
        <button
          on:click="{(e) => onApplyTagClick('em', e)}"
          class="w-10 py-2 cursor-pointer text-gray-900 hover:text-green-700"
        >
          <i>Н</i>
        </button>
      </div>

      <div class="flex bg-blue-200 shadow-sm rounded mr-2">
        <button
          on:click="{() => dispatch('change-type', 'h1')}"
          class="w-10 py-2 cursor-pointer text-gray-900 hover:text-green-700"
          class:text-green-500="{data.type === 'h1'}"
        >
          <b>З</b><span class="text-sm">1</span>
        </button>
        <button
          on:click="{() => dispatch('change-type', 'h2')}"
          class="w-10 py-2 cursor-pointer text-gray-900 hover:text-green-700"
          class:text-green-500="{data.type === 'h2'}"
        >
          <b>З</b><span class="text-sm">2</span>
        </button>
      </div>

      <div class="flex bg-blue-200 shadow-sm rounded mr-2">
        <button
          on:click="{() => dispatch('change-type', 'quote')}"
          class="w-10 py-2 cursor-pointer text-gray-900 hover:text-green-700"
          class:text-green-500="{data.type === 'quote'}"
        >
          <i class="fas fa-quote-right"></i>
        </button>
      </div>

      <div class="flex bg-blue-200 shadow-sm rounded mr-2">
        <button class="w-10 py-2 cursor-pointer text-gray-900 hover:text-blue-700 relative">
          <input
            type="file"
            class="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            on:change="{(e) => fileSelect(e)}"
          />
          <i class="far fa-image"></i>
        </button>
      </div>

      <div class="flex bg-blue-200 shadow-sm rounded mr-2">
        <button
          on:click="{onLinkClick}"
          class="w-10 py-2 cursor-pointer text-gray-900 hover:text-blue-700 relative"
          class:text-green-500="{showNewLink}"
        >
          <i class="fas fa-link"></i>
        </button>
      </div>
    </div>

    {#if data.imageUrl && data.imageUrl.length}
      <div class="p-2">
        <div class="h-20 p-2 bg-blue-100 flex">
          <img class="object-cover max-h-full" src="{data.imageUrl}" alt="" />

          <div class="flex items-center px-8">
            <div class="bg-blue-200 mr-2">
              <button
                on:click="{() => changeImagePosition('left')}"
                class:text-green-500="{data.imagePosition === 'left'}"
                class="w-10 py-2 cursor-pointer text-gray-900 hover:text-green-700 "
              >
                <i class="fas fa-align-left"></i>
              </button>
              <button
                on:click="{() => changeImagePosition('right')}"
                class:text-green-500="{data.imagePosition === 'right'}"
                class="w-10 py-2 cursor-pointer text-gray-900 hover:text-green-700 "
              >
                <i class="fas fa-align-right"></i>
              </button>
              <button
                on:click="{() => changeImagePosition('center')}"
                class:text-green-500="{data.imagePosition === 'center'}"
                class="w-10 py-2 cursor-pointer text-gray-900 hover:text-green-700 "
              >
                <i class="fas fa-align-center"></i>
              </button>
              <button
                on:click="{() => changeImagePosition('wide')}"
                class:text-green-500="{data.imagePosition === 'wide'}"
                class="w-10 py-2 cursor-pointer text-gray-900 hover:text-green-700 "
              >
                <i class="fas fa-arrows-alt-h"></i>
              </button>
              <input on:blur bind:value="{data.imageCaption}" placeholder="описание" class="px-2 py-1 mr-4 w-64" />
            </div>

            <div class="bg-blue-200 mr-2">
              <button
                on:click="{() => removeImage()}"
                class="w-10 py-2 cursor-pointer text-gray-900 hover:text-green-700 "
              >
                <i class="far fa-trash-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    {/if}

    {#if showNewLink}
      <div class="p-2">
        <div class="bg-blue-100 p-2 flex flex-col items-center shadow-md">
          <div class="flex items-center justify-center mb-2">
            <label class="block">текст:</label>
            <input class="px-2 py-1 border border-blue-600 rounded mx-4" bind:value="{linkText}" />
          </div>

          <div class="flex items-center justify-center mb-2">
            <label class="block">введите адрес:</label>
            <input class="px-2 py-1 border border-blue-600 rounded mx-4" bind:value="{linkAddress}" />
          </div>

          <div class="flex items-center justify-center">
            <button
              class="px-3 py-1 border border-green-500 bg-white text-green-500 hover:bg-green-500 hover:text-white rounded"
              on:click="{onLinkAdd}"
            >
              добавить
            </button>
          </div>
        </div>
      </div>
    {/if}

    <textarea
      id="current-paragraph"
      bind:this="{textEl}"
      class="p-2 text-gray-900"
      class:text-3xl="{data.type === 'h1'}"
      class:text-2xl="{data.type === 'h2'}"
      on:keydown="{keypress}"
      bind:value="{content}"
      on:blur
      style="{`height: ${areaHeight}px`}"></textarea>
  </div>
{:else}
  <div class="paragraph-preview px-24 border border-solid border-gray-500 border-b-0 border-t-0 border-l-0">
    {#if data.imageUrl && data.imageUrl.length}
      <div class="h-20 p-2 flex">
        <img class="object-cover max-h-full" src="{data.imageUrl}" alt="" />
      </div>
    {/if}
    <div
      class:text-3xl="{data.type === 'h1'}"
      class:text-2xl="{data.type === 'h2'}"
      class="paragraph-preview-text py-8 text-gray-900 border-transparent border border-solid hover:border-gray-500 hover:bg-gray-100 cursor-pointer"
      on:click="{onParagraphClick}"
    >
      {@html content}
    </div>
  </div>
{/if}

<div class="text-green-500 text-3xl text-2xl"></div>

<style>
  .wysiwyg {
  }

  .paragraph-preview {
    /*width: 700px;*/
  }

  textarea {
    width: 100%;
  }
</style>
