<script lang="ts">
  import { Link } from 'svelte-navigator';
</script>

<div class="flex items-center my-4">
  <Link to="/">
    <img src="/img/slblack35.png" alt="logo" />
  </Link>

  <Link to="/">
    <div class="sk-logo-text">Skillady</div>
    <div class="under-logo-text">Женский журнал о стиле жизни</div>
  </Link>
</div>

<style>
  .sk-logo-container {
    position: relative;
    font-family: 'Libre Caslon Text', serif;
    width: 45px;
    height: 45px;
    border: #000000 solid 2px;
    border-radius: 50%;
  }

  .s,
  .k {
    position: absolute;
    font-size: 22px;
  }

  .s {
    left: 10px;
    top: 1px;
  }

  .k {
    left: 17px;
    top: 10px;
  }

  .sk-logo-text {
    text-transform: uppercase;
    font-family: 'Libre Caslon Text', serif;
    font-size: 20px;
    letter-spacing: 10px;
    margin-left: 14px;
  }

  .under-logo-text {
    font-family: 'Roboto', serif;
    font-size: 11px;
    letter-spacing: 1px;
    margin-left: 14px;
  }
</style>
