<script lang="ts">
  import { link, navigate } from 'svelte-navigator';
  import { fly } from 'svelte/transition';

  import type { PostItem } from '../data/Post';

  export let post: PostItem;
  export let delay: number;

  let isActive: boolean = false;

  const onMouseEnter = () => {
    isActive = true;
  };

  const onMouseLeave = () => {
    isActive = false;
  };
</script>

<div
  class="mb-10 md:mb-20 cursor-pointer"
  class:active="{isActive}"
  in:fly="{{ y: 200, duration: 1000, delay: delay }}"
  on:mouseenter="{() => onMouseEnter()}"
  on:mouseleave="{() => onMouseLeave()}"
>
  <a
    href="{`/post/${post.name}`}"
    class="block text-3xl lg:text-3xl xl:text-4xl leading-tight text-gray-900 mb-6"
    use:link>{post.title}</a
  >
  {#if post.picture && post.picture.length}
    <div on:click="{() => navigate(`/post/${post.name}`)}" class="my-6 relative">
      <div class="hover-bg bg-gray-900 absolute w-full h-full inset-0"></div>
      <div class="hover-letter absolute inset-0 m-auto flex items-center justify-center">
        <span>S</span>
      </div>
      <div class="read-more-text text-gray-100 text-md absolute inset-0 flex items-center justify-center">
        Читать Далее
      </div>
      <div class="hover-line-top absolute"></div>
      <div class="hover-line-bottom absolute"></div>
      <img alt="" src="{post.picture}" class="w-full h-auto" />
    </div>
  {/if}
  <h1 class="text-lg text-gray-900 mb-6">{post.shortText}</h1>
  <div
    class="text-xs uppercase tracking-wider border border-gray-300 border-solid border-l-0 border-r-0 border-t-0 pb-1"
  >
    <span class="text-gray-600">{post.datePublished}</span> /
    <a href="/category/{post.category}" use:link class="text-gray-600">{post.category}</a>
    /
    <a href="/info/author/{post.authorId}" use:link class="text-gray-600">{post.authorName}</a>
  </div>
</div>

<style>
  .hover-bg {
    opacity: 0;
    transition: all 300ms ease-in-out;
  }

  .active .hover-bg {
    opacity: 0.5;
  }

  .hover-letter span {
    font-family: 'Libre Caslon Text', serif;
    font-size: 13rem;
    line-height: 13rem;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transform: scale(1.5);

    transition: all 300ms ease-in-out;
  }

  .active .hover-letter span {
    opacity: 1;
    transform: scale(1);
  }

  .read-more-text {
    letter-spacing: 1px;
    opacity: 0;

    transition: all 300ms ease-in-out;
  }

  .active .read-more-text {
    opacity: 1;
  }

  .hover-line-top,
  .hover-line-bottom {
    width: 1px;
    height: 20%;
    left: 50%;
    opacity: 0;
    background: #ffffff;
    transform: scale(0) rotate(45deg);

    transition: all 300ms ease-in-out;
  }

  .hover-line-top {
    bottom: 70%;
    transform-origin: 0 200%;
  }

  .hover-line-bottom {
    top: 70%;
    transform-origin: 0 -100%;
  }

  .active .hover-line-top,
  .active .hover-line-bottom {
    opacity: 1;
    transform: scale(1) rotate(45deg);
  }
</style>
