<script lang="ts">
  import AppLogo from './AppLogo.svelte';
</script>

<div class="hidden lg:block lg:container mx-auto px-8">
  <div
    class="flex items-center justify-between border border-solid border-gray-600 border-l-0 border-r-0 border-b-0 py-2"
  >
    <AppLogo />
    <div class="flex flex-col items-end text-xs">
      <div>&copy; 2020 All rights reserved.</div>
      <div>Использование материалов skillady.com допустимо только при указании источника. +16</div>
    </div>
  </div>
</div>

<div class="flex lg:hidden flex-col items-center text-xs py-6 px-6">
  <div>&copy; 2020 All rights reserved.</div>
  <div class="text-center">Использование материалов skillady.com допустимо только при указании источника. +16</div>
</div>
